import { combineReducers } from "redux";
import auth from "./Auth";
import theme from "./Theme";
import company from "./Company";
import user from "./User";
import project from "./project";
import userManagement from "./userManagement";
import compliance from "./compliance";
import cxoadmin from "./cxoAdmin";
import userCompliance from "./UserCompliances";
import filters from "./Filters";

export default combineReducers({
  auth,
  theme,
  company,
  user,
  userManagement,
  project,
  compliance,
  cxoadmin,
  userCompliance,
  filters,
});
