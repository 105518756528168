import {
  LIST_COMPLIANCE_ADMIN_SUCCESS,
  LIST_COMPLIANCE_ADMIN_FAILED,
  REMOVE_COMPLIANCE_ADMIN_ALERT,
  CREATE_COMPLIANCE_ADMIN_SUCCESS,
  CREATE_COMPLIANCE_ADMIN_FAILED,
  SET_ISOPEN_COMPLIANCE,
  LIST_COMPLIANCE_MASTER_SUCCESS,
  LIST_COMPLIANCE_MASTER_FAILED,
  COMPLIANCE_MASTER_SUCCESS,
  COMPLIANCE_MASTER_FAILED,
  SET_EDIT_COMPLIANCE_DATA,
  LIST_COMPANY_COMPLIANCE_FAILED,
  LIST_COMPANY_COMPLIANCE_SUCCESS,
  SET_COMPNAY_COMPLIANCE_ID,
  LIST_COMPANY_COMPLIANCE_AVAILABLE_FAILED,
  LIST_COMPANY_COMPLIANCE_AVAILABLE_SUCCESS,
  COMPLIANCE_ALERT_SET,
  COMPLIANCE_ALERT_REMOVE,
  LIST_DIVISION_COMPLIANCE_FAILED,
  LIST_DIVISION_COMPLIANCE_SUCCESS,
  CREATE_DIVISION_USER_FAILED,
  CREATE_DIVISION_USER_SUCCESS,
  SET_ISOPEN_DIVISION_USER,
  SET_EDIT_COMPLIANCE_DATA_API,
  GLOBAL_ALERT_SYSTEM,
  LOGOUT,
  LIST_COMPLIANCE_MASTER_LOADING,
  LIST_COMPANY_COMPLIANCE_LOADING,
  LIST_COMPANY_AVAILABLE_COMPLIANCE_LOADING,
  LIST_DIVISION_COMPLIANCE_LOADING,
  SET_BULK_LOADER,
  SET_BUILK_MESSAGE,
  SET_RANGE_FOR_LIST,
  USER_DOWNLOAD_CERTIFICATE_PARAMETER,
  MASTER_DOWNLOAD_CERTIFICATE_PARAMETER,
  COMPLIANCE_MAPPING_PARAMETER,
  COMPANAY_COMPLIANCE_MAPPING_PARAMETER,
} from '../../actions/types';
const initialState = {
  complianceAdmin: [],
  complianceMasterList: [],
  complianceMasterListLoading: false,
  isError: false,
  adminloading: true,
  isErrorType: null,
  errMessage: null,
  isOpen: false,
  editComplianceDataId: null,
  editComplianceDataBool: false,
  editComplianceData: {},
  companyCompliance: [],
  companyComplianceLoading: false,
  availableCompanyComplianceLoading: false,
  availableCompanyCompliance: [],
  companyComplianceId: null,
  isAlert: false,
  isAlertError: false,
  alertMessage: null,
  divisionCompliance: [],
  globalAlert: false,
  globalAlertType: null,
  globalAlertMessage: null,
  divisionComplianceLoading: false,
  bulkUploading: false,
  bisError: false,
  bisErrorType: null,
  berrMessage: null,
  totalRecords: 0,
  companyTotalRecords: 0,
  complianceRangeSelected: 0 + ' - ' + 5000,
  divisionUserCompliance: false,
  divisionUserComplianceMessage: '',
  masterCompliance: false,
  masterComplianceMessage: '',
  complianceMapping: false,
  complianceMappingMessage: '',
  companyComplianceMap: false,
  companyComplianceMapMessage: '',
};

function complianceReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case COMPANAY_COMPLIANCE_MAPPING_PARAMETER:
      return {
        ...state,
        companyComplianceMap: payload.loading,
        companyComplianceMapMessage: payload.message,
      };
    case COMPLIANCE_MAPPING_PARAMETER:
      return {
        ...state,
        complianceMapping: payload.loading,
        complianceMappingMessage: payload.message,
      };
    case MASTER_DOWNLOAD_CERTIFICATE_PARAMETER:
      return {
        ...state,
        masterCompliance: payload.loading,
        masterComplianceMessage: payload.message,
      };
    case USER_DOWNLOAD_CERTIFICATE_PARAMETER:
      return {
        ...state,
        divisionUserCompliance: payload.loading,
        divisionUserComplianceMessage: payload.message,
      };
    case SET_RANGE_FOR_LIST:
      return {
        ...state,
        complianceRangeSelected: payload,
      };
    case SET_BUILK_MESSAGE:
      return {
        ...state,
        bisError: payload.error,
        bisErrorType: payload.type,
        berrMessage: payload.message,
      };
    case SET_BULK_LOADER:
      return {
        ...state,
        bulkUploading: payload,
      };
    case LIST_DIVISION_COMPLIANCE_LOADING:
      return {
        ...state,
        divisionComplianceLoading: payload,
      };
    case LIST_COMPANY_COMPLIANCE_LOADING:
      return {
        ...state,
        companyComplianceLoading: payload,
      };
    case LIST_COMPANY_AVAILABLE_COMPLIANCE_LOADING:
      return {
        ...state,
        availableCompanyComplianceLoading: payload,
      };
    case LOGOUT:
      return {
        complianceAdmin: [],
        complianceMasterList: [],
        isError: false,
        adminloading: true,
        isErrorType: null,
        errMessage: null,
        isOpen: false,
        editComplianceDataId: null,
        editComplianceDataBool: false,
        editComplianceData: {},
        companyCompliance: [],
        availableCompanyCompliance: [],
        companyComplianceId: null,
        isAlert: false,
        isAlertError: false,
        alertMessage: null,
        divisionCompliance: [],
        globalAlert: false,
        globalAlertType: null,
        globalAlertMessage: null,
      };
    case GLOBAL_ALERT_SYSTEM:
      return {
        ...state,
        globalAlert: payload.isAlert,
        globalAlertType: payload.alertType,
        globalAlertMessage: payload.alertMessage,
      };
    case LIST_COMPLIANCE_MASTER_LOADING:
      return {
        ...state,
        complianceMasterListLoading: payload,
      };
    case COMPLIANCE_ALERT_SET: {
      return {
        ...state,
        isAlert: payload.isAlert,
        isAlertError: payload.isError,
        alertMessage: payload.message,
      };
    }
    case COMPLIANCE_ALERT_REMOVE: {
      return {
        ...state,
        isAlert: false,
        isAlertError: false,
        alertMessage: null,
      };
    }
    case LIST_COMPLIANCE_ADMIN_FAILED:
      return {
        ...state,
        complianceAdmin: [],
        isError: true,
        errMessage: payload.message,
        isErrorType: 'COMANY_LIST_ERROR',
        adminloading: false,
      };

    case LIST_COMPLIANCE_ADMIN_SUCCESS:
      return {
        ...state,
        isError: false,
        adminloading: false,
        complianceAdmin: payload.data,
      };

    case LIST_COMPLIANCE_MASTER_FAILED:
      return {
        ...state,
        complianceMasterList: [],
        isError: true,
        errMessage: payload.message,
        isErrorType: 'COMANY_LIST_ERROR',
        adminloading: false,
      };

    case LIST_COMPLIANCE_MASTER_SUCCESS:
      return {
        ...state,
        isError: false,
        adminloading: false,
        isErrorType: null,
        complianceMasterList: payload.data,
        totalRecords: payload.total,
      };
    case REMOVE_COMPLIANCE_ADMIN_ALERT:
      return {
        ...state,
        isError: false,
        isErrorType: null,
        errMessage: null,
        isOpen: false,
      };
    case CREATE_COMPLIANCE_ADMIN_SUCCESS:
    case CREATE_DIVISION_USER_SUCCESS:
      return {
        ...state,
        isError: false,
        isErrorType: payload.type,
        errMessage: payload.message,
      };
    case CREATE_COMPLIANCE_ADMIN_FAILED:
    case CREATE_DIVISION_USER_FAILED:
      return {
        ...state,
        isError: true,
        isErrorType: payload.type,
        errMessage: payload.message,
      };
    case SET_ISOPEN_DIVISION_USER:
    case SET_ISOPEN_COMPLIANCE:
      return {
        ...state,
        isOpen: true,
      };

    case COMPLIANCE_MASTER_SUCCESS:
    case COMPLIANCE_MASTER_FAILED:
      return {
        ...state,
        isError: true,
        isErrorType: payload.type,
        errMessage: payload.message,
      };
    case SET_EDIT_COMPLIANCE_DATA:
      return {
        ...state,
        editComplianceDataId: payload.id,
        editComplianceData: payload.data,
        editComplianceDataBool: payload.isCompany,
      };
    case SET_EDIT_COMPLIANCE_DATA_API:
      return {
        ...state,
        editComplianceData: payload,
      };
    case LIST_COMPANY_COMPLIANCE_FAILED:
      return {
        ...state,
        isError: true,
        companyCompliance: payload.data,
      };
    case LIST_COMPANY_COMPLIANCE_SUCCESS:
      return {
        ...state,
        isError: false,
        errMessage: payload.message,
        companyCompliance: payload.data,
        companyTotalRecords: payload.total,
      };
    case LIST_DIVISION_COMPLIANCE_FAILED:
      return {
        ...state,
        isError: true,
        errMessage: payload.message,
        divisionCompliance: [],
      };
    case LIST_DIVISION_COMPLIANCE_SUCCESS:
      return {
        ...state,
        divisionCompliance: payload.data,
      };
    case LIST_COMPANY_COMPLIANCE_AVAILABLE_FAILED:
      return {
        ...state,
        isError: true,
        availableCompanyCompliance: payload.data,
      };
    case LIST_COMPANY_COMPLIANCE_AVAILABLE_SUCCESS:
      return {
        ...state,
        isError: false,
        errMessage: payload.message,
        availableCompanyCompliance: payload.data,
      };
    case SET_COMPNAY_COMPLIANCE_ID:
      return {
        ...state,
        companyComplianceId: payload,
      };
    default:
      return state;
  }
}

export default complianceReducer;
