import {
  ADD_USER_LIST_DATA_FAILED,
  ADD_USER_LIST_DATA_SUCCESS,
  EDIT_USER_LIST_DATA_FAILED,
  EDIT_USER_LIST_DATA_SUCCESS,
  LIST_USER_BY_EMAIL_DATA_SUCESS,
  LIST_USER_COMPANY_LIST_SUCCESS,
  LIST_USER_LIST_DATA_FAILED,
  LIST_USER_LIST_DATA_SUCCESS,
  LIST_USER_PROJECT_LIST_SUCCESS,
  LOGOUT,
  REMOVE_ISOPEN_USER_LIST_DATA,
  REMOVE_USER_LIST_DATA_ALERT,
  SET_ISOPEN_USER_LIST_DATA,
} from '../../actions/types';
const initialState = {
  loading: true,
  isError: false,
  isErrorType: null,
  errMessage: null,
  isUser: null,
  data: [],
  isOpen: false,
  userByEmail: {},
  userCompanyloading: true,
  userCompnay: [],
  userProjectloading: true,
  userProject: [],
};

function UserManagementReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LOGOUT:
      return {
        loading: true,
        isError: false,
        isErrorType: null,
        errMessage: null,
        isUser: null,
        data: [],
        isOpen: false,
        userByEmail: {},
        userCompanyloading: true,
        userCompnay: [],
        userProjectloading: true,
        userProject: [],
      };
    case LIST_USER_LIST_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: payload.data,
      };
    case LIST_USER_LIST_DATA_FAILED:
      return {
        ...state,
        loading: false,
        data: [],
      };
    case SET_ISOPEN_USER_LIST_DATA:
      return {
        ...state,
        isOpen: true,
      };
    case EDIT_USER_LIST_DATA_FAILED:
    case ADD_USER_LIST_DATA_FAILED:
      return {
        ...state,
        isError: true,
        isErrorType: payload.type,
        errMessage: payload.message,
      };
    case EDIT_USER_LIST_DATA_SUCCESS:
    case ADD_USER_LIST_DATA_SUCCESS:
      return {
        ...state,
        isError: false,
        isErrorType: payload.type,
        errMessage: payload.message,
      };
    case REMOVE_ISOPEN_USER_LIST_DATA:
      return {
        ...state,
        isOpen: false,
        isError: false,
        errMessage: null,
        isErrorType: null,
      };
    case REMOVE_USER_LIST_DATA_ALERT:
      return {
        ...state,
        isError: false,
        isErrorType: null,
        errMessage: null,
      };
    case LIST_USER_BY_EMAIL_DATA_SUCESS:
      return {
        ...state,
        userByEmail: payload.data,
      };
    case LIST_USER_COMPANY_LIST_SUCCESS:
      return {
        ...state,
        userCompanyloading: false,
        userCompnay: payload,
      };
    case LIST_USER_PROJECT_LIST_SUCCESS:
      return {
        ...state,
        userProjectloading: false,
        userProject: payload,
      };
    default:
      return state;
  }
}

export default UserManagementReducer;
