import axios from "axios";
import {
  ADD_OLD_USER_ID,
  BACKEND_URL,
  CLOSE_EDIT_MODAL,
  CLOSE_EDIT_MODAL_RESET,
  CREATE_COMPANY_FAILED,
  DEACTIVATE_COMPANY_FAILED,
  DEACTIVATE_COMPANY_SUCCESS,
  EDIT_COMPANY_DATA,
  EDIT_COMPNAY_FAILED,
  EDIT_COMPNAY_SUCCESS,
  LIST_COMPANY_FAILED,
  LIST_COMPANY_SUCESS,
  SET_LOGOUT_PROVISION,
  REMOVE_COMPANY_ALERT,
  RESET_ERROR_USER,
  SETTINGS_DATA_SUCCESS,
  BACKEND_URL_2,
} from "../../actions/types";
import store from "../../redux/store";
import { getRole } from "../login/user";

export const createCompanyAPI =
  (name, city, state, country, state_pin_code, address1, address2) =>
  async (dispatch) => {
    const config = {
      "Content-Type": "application/json",
    };
    const company_id = store.getState().auth.data.company_id;
    const body = {
      company_id,
      name,
      city,
      state,
      country,
      state_pin_code,
      address1,
      address2,
    };
    await axios({
      method: "POST",
      url: BACKEND_URL + "org/create-company",
      data: body,
      headers: config,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          await dispatch(
            getRole(
              "COMPANY-ADMIN",
              response.data.data,
              "COMPANY-MANAGEMENT",
              "ADD",
              response.data
            )
          );
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
        } else {
          dispatch({
            type: CREATE_COMPANY_FAILED,
            payload: {
              message: response.data.message,
              type: "COMPANY_MANAGEMENT",
            },
          });
          setTimeout(() => {
            dispatch({ type: REMOVE_COMPANY_ALERT });
          }, 12000);
        }
      })
      .catch((error) => {
        dispatch({
          type: CREATE_COMPANY_FAILED,
          payload: {
            message: "Server Error",
            type: "COMPANY_MANAGEMENT",
          },
        });
        setTimeout(() => {
          dispatch({ type: REMOVE_COMPANY_ALERT });
        }, 12000);
      });
  };

let listCompanyAbort = null;
export const listCompany = () => async (dispatch) => {
  if (listCompanyAbort !== null) {
    listCompanyAbort.cancel("New Call made");
    listCompanyAbort = null;
  }
  listCompanyAbort = axios.CancelToken.source();
  const config = {
    "Content-Type": "application/json",
  };
  const company_id = store.getState().auth.data.company_id;
  axios({
    method: "POST",
    url: BACKEND_URL + "org/list-company",
    data: { company_id },
    headers: config,
    cancelToken: listCompanyAbort.token,
  })
    .then(async (response) => {
      if (response.data.statuscode === 200) {
        dispatch({
          type: LIST_COMPANY_SUCESS,
          payload: {
            message: response.data.message,
            type: "COMPANY_MANAGEMENT",
            data: response.data.data,
          },
        });
      } else if (response.data.statuscode === 403) {
        await dispatch({ type: SET_LOGOUT_PROVISION });
      } else {
        dispatch({
          type: LIST_COMPANY_FAILED,
          payload: {
            message: response.data.message,
            type: "COMPANY_MANAGEMENT",
            data: null,
          },
        });
      }
    })
    .catch((error) => {
      setTimeout(() => {
        dispatch({ type: REMOVE_COMPANY_ALERT });
      }, 12000);
    });
};

export const deactivateCompany = (client_id, status) => async (dispatch) => {
  const config = {
    "Content-Type": "application/json",
  };
  const company_id = store.getState().auth.data.company_id;
  const body = { company_id, company: client_id, status };
  await axios({
    method: "POST",
    url: BACKEND_URL_2 + "project-management/deactivate-company",
    data: body,
    headers: config,
  })
    .then(async (response) => {
      if (response.data.statuscode === 200) {
        dispatch({
          type: DEACTIVATE_COMPANY_SUCCESS,
          payload: {
            message: response.data.message,
            type: "COMPANY_MANAGEMENT",
          },
        });
        dispatch(listCompany());
      } else if (response.data.statuscode === 403) {
        await dispatch({ type: SET_LOGOUT_PROVISION });
      } else {
        dispatch({
          type: DEACTIVATE_COMPANY_FAILED,
          payload: {
            message: response.data.message,
            type: "COMPANY_MANAGEMENT",
          },
        });
        setTimeout(() => {
          dispatch({ type: REMOVE_COMPANY_ALERT });
        }, 12000);
      }
    })
    .catch((error) => {
      setTimeout(() => {
        dispatch({ type: REMOVE_COMPANY_ALERT });
      }, 12000);
    });
};

export const listCompanyByEmail = (client_id, email) => async (dispatch) => {
  const config = {
    "Content-Type": "application/json",
  };
  const company_id = store.getState().auth.data.company_id;
  await axios({
    method: "POST",
    url: BACKEND_URL + "org/get-company",
    data: { company_id, client_id },
    headers: config,
  })
    .then(async (response) => {
      if (response.data.statuscode === 200) {
        response.data.data.email = email;
        await dispatch({
          type: EDIT_COMPANY_DATA,
          payload: response.data.data,
        });
        await dispatch({
          type: ADD_OLD_USER_ID,
          payload: response.data.data.user_id,
        });
      } else if (response.data.statuscode === 403) {
        await dispatch({ type: SET_LOGOUT_PROVISION });
      } else {
        dispatch({
          type: LIST_COMPANY_FAILED,
          payload: {
            message: response.data.message,
            type: "COMPANY_MANAGEMENT",
            data: null,
          },
        });
      }
    })
    .catch((error) => {
      setTimeout(() => {
        dispatch({ type: REMOVE_COMPANY_ALERT });
      }, 12000);
    });
};

export const editCompanyAPI =
  (name, city, state, country, state_pin_code, address1, address2, isNew) =>
  async (dispatch) => {
    const config = {
      "Content-Type": "application/json",
    };
    const company_id = store.getState().auth.data.company_id;
    const client_id = store.getState().company.editCompany.id;
    const body = {
      company_id,
      client_id,
      name,
      city,
      state,
      country,
      state_pin_code,
      address1,
      address2,
    };
    await axios({
      method: "POST",
      url: BACKEND_URL + "org/edit-company",
      data: body,
      headers: config,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          dispatch(listCompany());
          if (!isNew) {
            dispatch({
              type: EDIT_COMPNAY_SUCCESS,
              payload: {
                message: response.data.message,
                type: "COMPANY_CREATED",
                data: client_id,
              },
            });
            setTimeout(() => {
              dispatch({ type: REMOVE_COMPANY_ALERT });
              dispatch({ type: CLOSE_EDIT_MODAL });
              dispatch({ type: CLOSE_EDIT_MODAL_RESET });
              dispatch({ type: RESET_ERROR_USER });
            }, 4000);
          } else {
            await dispatch(
              getRole(
                "COMPANY-ADMIN",
                client_id,
                "COMPANY-MANAGEMENT",
                "EDIT",
                response.data
              )
            );
          }
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
        } else if (response.data.statuscode === 500) {
          dispatch({
            type: EDIT_COMPNAY_FAILED,
            payload: {
              message: "Server Error",
              type: "COMPANY_MANAGEMENT",
            },
          });
          setTimeout(() => {
            dispatch({ type: REMOVE_COMPANY_ALERT });
          }, 12000);
        } else {
          dispatch({
            type: EDIT_COMPNAY_FAILED,
            payload: {
              message: response.data.message,
              type: "COMPANY_MANAGEMENT",
            },
          });
          setTimeout(() => {
            dispatch({ type: REMOVE_COMPANY_ALERT });
          }, 12000);
        }
      })
      .catch((error) => {
        setTimeout(() => {
          dispatch({ type: REMOVE_COMPANY_ALERT });
        }, 12000);
      });
  };

export const listCompanyDetails = () => async (dispatch) => {
  const config = {
    "Content-Type": "application/json",
  };
  const client_id = sessionStorage.getItem("company_selected");
  await axios({
    method: "POST",
    url: BACKEND_URL + "org/get-company-info",
    data: { client_id },
    headers: config,
  })
    .then(async (response) => {
      if (response.data.statuscode === 200) {
        dispatch({
          type: SETTINGS_DATA_SUCCESS,
          payload: response.data.data,
        });
      } else if (response.data.statuscode === 403) {
        await dispatch({ type: SET_LOGOUT_PROVISION });
      } else {
      }
    })
    .catch((error) => {});
};

export const saveCompanyDetails = (formData) => async (dispatch) => {
  const config = {
    "Content-Type": "application/json",
  };
  const client_id = sessionStorage.getItem("company_selected");

  var formDataUp = new FormData();
  formDataUp.append("client_id", client_id);
  if (formData.city !== "") formDataUp.append("city", formData.city);
  if (formData.state !== "") formDataUp.append("state", formData.state);
  if (formData.country !== "") formDataUp.append("country", formData.country);
  if (formData.zipcode !== "") formDataUp.append("zipcode", formData.zipcode);
  if (formData.logo.name !== undefined) {
    if (
      formData.logo.type === "image/jpg" ||
      formData.logo.type === "image/jpeg" ||
      formData.logo.type === "image/png"
    ) {
      formDataUp.append("logo", formData.logo);
    }
  }
  if (formData.corporate_type !== "")
    formDataUp.append("corporate_type", formData.corporate_type);
  if (formData.industry_type !== "")
    formDataUp.append("type", formData.industry_type);
  if (formData.employee_size !== "")
    formDataUp.append("number_of_emploies", formData.employee_size);
  if (formData.links !== "") formDataUp.append("links", formData.links);
  if (formData.about !== "") formDataUp.append("about", formData.about);
  await axios({
    method: "POST",
    url: BACKEND_URL + "org/edit-company-info",
    data: formDataUp,
    headers: config,
  })
    .then(async (response) => {
      if (response.data.statuscode === 200) {
        dispatch({
          type: EDIT_COMPNAY_SUCCESS,
          payload: {
            message: response.data.message,
            type: "COMPANY_CREATED",
            data: client_id,
          },
        });
        setTimeout(() => {
          dispatch({ type: REMOVE_COMPANY_ALERT });
          dispatch({ type: CLOSE_EDIT_MODAL });
          dispatch({ type: CLOSE_EDIT_MODAL_RESET });
          dispatch({ type: RESET_ERROR_USER });
        }, 10000);
      } else if (response.data.statuscode === 403) {
        await dispatch({ type: SET_LOGOUT_PROVISION });
      } else if (response.data.statuscode === 500) {
        dispatch({
          type: EDIT_COMPNAY_FAILED,
          payload: {
            message: "Server Error",
            type: "COMPANY_MANAGEMENT",
          },
        });
        setTimeout(() => {
          dispatch({ type: REMOVE_COMPANY_ALERT });
        }, 12000);
      } else {
        dispatch({
          type: EDIT_COMPNAY_FAILED,
          payload: {
            message: response.data.message,
            type: "COMPANY_MANAGEMENT",
          },
        });
        setTimeout(() => {
          dispatch({ type: REMOVE_COMPANY_ALERT });
        }, 12000);
      }
    })
    .catch((error) => {
      setTimeout(() => {
        dispatch({ type: REMOVE_COMPANY_ALERT });
      }, 12000);
    });
};
