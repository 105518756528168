import {
  APPROVAL_RELOAD,
  APPROVAL_TOTAL_RECORDS,
  CERTIFICATE_TOTAL_RECORDS,
  COMPANY_COMPLIANCE_TOTAL_RECORDS,
  DASHBOARD_TOTAL_RECORDS,
  DIVISION_TOTAL_RECORDS,
  EVENTS_RELOAD,
  EVENTS_TOTAL_RECORDS,
  MASTER_COMPLIANCE_RELOAD,
  MY_COMPLIANCE_RELOAD,
  MY_COMPLIANCE_TOTAL_RECORDS,
} from "../../actions/types";
const initialState = {
  masterLoading: false,
  myComplianceTotal: 0,
  myComplianceReload: false,
  approvalTotal: 0,
  approvalReload: false,
  eventsTotal: 0,
  eventsReload: false,
  dashboardTotal: 0,
  certificateTotal: 0,
  companyComplianceTotal: 0,
  divisionComplianceTotal: 0,
};

function filtersReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case MASTER_COMPLIANCE_RELOAD:
      return {
        ...state,
        masterLoading: payload,
      };
    case MY_COMPLIANCE_TOTAL_RECORDS:
      return {
        ...state,
        myComplianceTotal: payload,
      };
    case MY_COMPLIANCE_RELOAD:
      return {
        ...state,
        myComplianceReload: payload,
      };
    case APPROVAL_TOTAL_RECORDS:
      return {
        ...state,
        approvalTotal: payload,
      };
    case APPROVAL_RELOAD:
      return {
        ...state,
        approvalReload: payload,
      };
    case EVENTS_TOTAL_RECORDS:
      return {
        ...state,
        eventsTotal: payload,
      };
    case EVENTS_RELOAD:
      return {
        ...state,
        eventsReload: payload,
      };
    case DASHBOARD_TOTAL_RECORDS:
      return {
        ...state,
        dashboardTotal: payload,
      };
    case COMPANY_COMPLIANCE_TOTAL_RECORDS:
      return {
        ...state,
        companyComplianceTotal: payload,
      };
    case DIVISION_TOTAL_RECORDS:
      return {
        ...state,
        divisionComplianceTotal: payload,
      };
    case CERTIFICATE_TOTAL_RECORDS:
      return {
        ...state,
        certificateTotal: payload,
      };
    default:
      return state;
  }
}

export default filtersReducer;
