import {
  CLEAR_INSTANCE_DATA,
  CLEAR_INSTANCE_DATA_SAVE,
  GET_REASONS,
  LIST_APPROVAL_LOADING,
  LIST_CERTIFCATE_DATA,
  LIST_CERTIFCATE_DATA_LOADING,
  REPORT_COMPLIANCE_STATUS,
  LIST_DASHBOARD_DATA,
  LIST_DASHBOARD_DATA_LOADING,
  LIST_DETAILED_INSTANCE_FAILED,
  LIST_DETAILED_INSTANCE_SUCCESS,
  LIST_EVENTS_LOADING,
  LIST_EVENT_BASED_INSTANCE_FAILED,
  LIST_EVENT_BASED_INSTANCE_SUCCESS,
  LIST_INSTANCE_FOR_APPROVAL,
  LIST_INSTANCE_FOR_EVENTS,
  LIST_MY_COMPLIANCE_1_DATA,
  LIST_MY_COMPLIANCE_2_DATA,
  LIST_MY_COMPLIANCE_DETAIL_FAILED,
  LIST_MY_COMPLIANCE_DETAIL_SUCCESS,
  LIST_SAVED_DETAILED_INSTANCE_FAILED,
  LIST_SAVED_DETAILED_INSTANCE_SUCCESS,
  LIST_TASKS_LOADING,
  LIST_TASK_INSTANCE_DATA,
  LIST_TIME_BASED_INSTANCE_FAILED,
  LIST_TIME_BASED_INSTANCE_SUCCESS,
  LOGOUT,
  RESET_ALERT_FOR_MY_COMPLIANCE,
  SET_ALERT_FOR_MY_COMPLIANCE,
  SET_USER_ROLE,
  TRIGGER_API,
  TRIGGER_API_2,
  REPORT_COMPLIANCE_STATUS_LOADING,
  R_TRIGGER_API_1,
  REPORT_DELAYED_COMPLIANCE,
  REPORT_DELAYED_COMPLIANCE_LOADING,
  R_TRIGGER_API_2,
  REPORT_EVENT_CHECKLIST,
  REPORT_EVENT_CHECKLIST_LOADING,
  R_TRIGGER_API_3,
  REPORT_EVENT_OCCURANCE,
  REPORT_EVENT_OCCURANCE_LOADING,
  R_TRIGGER_API_4,
  REPORT_CHECKER_VERIFICATION,
  REPORT_CHECKER_VERIFICATION_LOADING,
  R_TRIGGER_API_5,
  REPORT_ACTION_PLAN,
  REPORT_ACTION_PLAN_LOADING,
  R_TRIGGER_API_6,
  REPORT_CERTIFICATE_GENERATION,
  REPORT_CERTIFICATE_GENERATION_LOADING,
  R_TRIGGER_API_7,
  REPORT_COMPLIANCE_COUNT,
  REPORT_COMPLIANCE_COUNT_LOADING,
  R_TRIGGER_API_8,
  REPORT_USER_LOGIN,
  REPORT_USER_LOGIN_LOADING,
  R_TRIGGER_API_9,
  REPORT_NOT_APPLICABLE,
  REPORT_NOT_APPLICABLE_LOADING,
  R_TRIGGER_API_10,
  SHOW_APPROVAL_TAB,
  UPLOADING_FILES_MY_COMPLIANCE,
  INSTANCE_LOADING_DATA,
  SET_DOWNLOAD_CERTIFICATE_PARAMETER,
  SET_DOWNLOAD_ACTIVITY_LOG,
  COMPLIANCE_DATA_DASHBOARD,
} from '../../actions/types';
const initialState = {
  loading: true,
  isAlert: false,
  isAlertType: null,
  alertMessage: null,
  myComplianceOneData: [],
  myComplianceOneDataLoading: false,
  instanceDataLoaoding: false,
  instanceData: [],
  instaceType: null,
  instanceDetails: {},
  instanceSaveDetails: {},
  currentUser: null,
  approvalList: [],
  eventsList: [],
  calenderData: [],
  approvalLoading: false,
  eventsLoading: false,
  calendarLoading: false,
  dashboardData: [],
  dashboardDataLoading: false,
  certificateData: [],
  certificateDataLoading: false,
  triggerApi: false,
  triggerApi2: true,
  reasons: {},
  mtriggerAPi1: true,
  complianceStatus: [],
  complianceStatusLoading: false,
  mtriggerAPi2: true,
  delayedCompliance: [],
  delayedComplianceLoading: false,
  mtriggerAPi3: true,
  eventChecklist: [],
  eventChecklistLoading: false,
  mtriggerAPi4: true,
  eventOccurance: [],
  eventOccuranceLoading: false,
  mtriggerAPi5: true,
  checkerVerify: [],
  checkerVerifyLoading: false,
  mtriggerAPi6: true,
  actionPlan: [],
  actionPlanLoading: false,
  mtriggerAPi7: true,
  certiGen: [],
  certiGenLoading: false,
  mtriggerAPi8: true,
  complianceCount: [],
  complianceCountLoading: false,
  mtriggerAPi9: true,
  loginReport: [],
  loginReportLoading: false,
  mtriggerAPi10: true,
  notApplicable: [],
  notApplicableLoading: false,
  showApprovalTab: true,
  isFileUploading: false,
  isFileUploadingMessage: null,
  singleLoadingData: false,
  certificateDownloading: false,
  certificateDownloadMessage: '',
  downloadActivityLogLoader: false,
  complianceDashaboardLoading: false,
  complianceDashaboard: [],
};

function UserManagementReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_DOWNLOAD_ACTIVITY_LOG:
      return {
        ...state,
        downloadActivityLogLoader: payload.loading,
      };
    case SET_DOWNLOAD_CERTIFICATE_PARAMETER:
      return {
        ...state,
        certificateDownloading: payload.loading,
        certificateDownloadMessage: payload.message,
      };
    case INSTANCE_LOADING_DATA:
      return {
        ...state,
        singleLoadingData: payload,
      };
    case SHOW_APPROVAL_TAB:
      return {
        ...state,
        showApprovalTab: payload,
      };
    case REPORT_COMPLIANCE_STATUS:
      return {
        ...state,
        complianceStatus: payload,
      };
    case REPORT_COMPLIANCE_STATUS_LOADING:
      return {
        ...state,
        complianceStatusLoading: payload,
      };
    case R_TRIGGER_API_1:
      return {
        ...state,
        mtriggerAPi1: payload,
      };
    case REPORT_DELAYED_COMPLIANCE:
      return {
        ...state,
        delayedCompliance: payload,
      };
    case REPORT_DELAYED_COMPLIANCE_LOADING:
      return {
        ...state,
        delayedComplianceLoading: payload,
      };
    case R_TRIGGER_API_2:
      return {
        ...state,
        mtriggerAPi2: payload,
      };
    case REPORT_EVENT_CHECKLIST:
      return {
        ...state,
        eventChecklist: payload,
      };
    case REPORT_EVENT_CHECKLIST_LOADING:
      return {
        ...state,
        eventChecklistLoading: payload,
      };
    case R_TRIGGER_API_3:
      return {
        ...state,
        mtriggerAPi3: payload,
      };
    case REPORT_EVENT_OCCURANCE:
      return {
        ...state,
        eventOccurance: payload,
      };
    case REPORT_EVENT_OCCURANCE_LOADING:
      return {
        ...state,
        eventOccuranceLoading: payload,
      };
    case R_TRIGGER_API_4:
      return {
        ...state,
        mtriggerAPi4: payload,
      };
    case REPORT_CHECKER_VERIFICATION:
      return {
        ...state,
        checkerVerify: payload,
      };
    case REPORT_CHECKER_VERIFICATION_LOADING:
      return {
        ...state,
        checkerVerifyLoading: payload,
      };
    case COMPLIANCE_DATA_DASHBOARD:
      return {
        ...state,
        complianceDashaboard: payload.data,
        complianceDashaboardLoading: payload.loading,
      };
    case R_TRIGGER_API_5:
      return {
        ...state,
        mtriggerAPi5: payload,
      };
    case REPORT_ACTION_PLAN:
      return {
        ...state,
        actionPlan: payload,
      };
    case REPORT_ACTION_PLAN_LOADING:
      return {
        ...state,
        actionPlanLoading: payload,
      };
    case R_TRIGGER_API_6:
      return {
        ...state,
        mtriggerAPi6: payload,
      };
    case REPORT_CERTIFICATE_GENERATION:
      return {
        ...state,
        certiGen: payload,
      };
    case REPORT_CERTIFICATE_GENERATION_LOADING:
      return {
        ...state,
        certiGenLoading: payload,
      };
    case R_TRIGGER_API_7:
      return {
        ...state,
        mtriggerAPi7: payload,
      };
    case REPORT_COMPLIANCE_COUNT:
      return {
        ...state,
        complianceCount: payload,
      };
    case REPORT_COMPLIANCE_COUNT_LOADING:
      return {
        ...state,
        complianceCountLoading: payload,
      };
    case R_TRIGGER_API_8:
      return {
        ...state,
        mtriggerAPi8: payload,
      };
    case REPORT_USER_LOGIN:
      return {
        ...state,
        loginReport: payload,
      };
    case REPORT_USER_LOGIN_LOADING:
      return {
        ...state,
        loginReportLoading: payload,
      };
    case R_TRIGGER_API_9:
      return {
        ...state,
        mtriggerAPi9: payload,
      };

    case REPORT_NOT_APPLICABLE:
      return {
        ...state,
        notApplicable: payload,
      };
    case REPORT_NOT_APPLICABLE_LOADING:
      return {
        ...state,
        notApplicableLoading: payload,
      };
    case R_TRIGGER_API_10:
      return {
        ...state,
        mtriggerAPi10: payload,
      };

    case GET_REASONS:
      return {
        ...state,
        reasons: payload,
      };
    case TRIGGER_API:
      return {
        ...state,
        triggerApi: payload,
      };
    case TRIGGER_API_2:
      return {
        ...state,
        triggerApi2: payload,
      };
    case LIST_CERTIFCATE_DATA_LOADING:
      return {
        ...state,
        certificateDataLoading: payload,
      };
    case LIST_CERTIFCATE_DATA:
      return {
        ...state,
        certificateData: payload,
      };
    case LIST_DASHBOARD_DATA_LOADING:
      return {
        ...state,
        dashboardDataLoading: payload,
      };
    case LIST_DASHBOARD_DATA:
      return {
        ...state,
        dashboardData: payload,
      };
    case LIST_APPROVAL_LOADING:
      return {
        ...state,
        approvalLoading: payload,
      };
    case LIST_TASKS_LOADING:
      return {
        ...state,
        calendarLoading: payload,
      };
    case LIST_EVENTS_LOADING:
      return {
        ...state,
        eventsLoading: payload,
      };
    case LIST_TASK_INSTANCE_DATA:
      return {
        ...state,
        calenderData: payload,
      };
    case LIST_INSTANCE_FOR_EVENTS:
      return {
        ...state,
        eventsList: payload,
      };
    case LIST_INSTANCE_FOR_APPROVAL:
      return {
        ...state,
        approvalList: payload,
      };
    case LOGOUT:
      return {
        ...state,
        loading: true,
        isAlert: false,
        isAlertType: null,
        alertMessage: null,
        myComplianceOneData: [],
        myComplianceOneDataLoading: false,
        instanceDataLoaoding: false,
        instanceData: [],
        instaceType: null,
        instanceDetails: {},
        instanceSaveDetails: {},
        currentUser: null,
        approvalList: [],
        eventsList: [],
        calenderData: [],
        approvalLoading: false,
        eventsLoading: false,
        calendarLoading: false,
        dashboardData: [],
        dashboardDataLoading: false,
        certificateData: [],
        certificateDataLoading: false,
        triggerApi: true,
        triggerApi2: true,
        reasons: {},
        mtriggerAPi1: true,
        complianceStatus: [],
        complianceStatusLoading: false,
        mtriggerAPi2: true,
        delayedCompliance: [],
        delayedComplianceLoading: false,
        mtriggerAPi3: true,
        eventChecklist: [],
        eventChecklistLoading: false,
        mtriggerAPi4: true,
        eventOccurance: [],
        eventOccuranceLoading: false,
        mtriggerAPi5: true,
        checkerVerify: [],
        checkerVerifyLoading: false,
        mtriggerAPi6: true,
        actionPlan: [],
        actionPlanLoading: false,
        mtriggerAPi7: true,
        certiGen: [],
        certiGenLoading: false,
        mtriggerAPi8: true,
        complianceCount: [],
        complianceCountLoading: false,
        mtriggerAPi9: true,
        loginReport: [],
        loginReportLoading: false,
        showApprovalTab: true,
        certificateDownloading: false,
        certificateDownloadMessage: '',
      };
    case SET_USER_ROLE:
      return {
        ...state,
        currentUser: payload,
      };
    case CLEAR_INSTANCE_DATA:
      return {
        ...state,
        instanceDetails: [],
      };
    case LIST_DETAILED_INSTANCE_SUCCESS:
      return {
        ...state,
        instanceDetails: payload.data,
      };
    case LIST_DETAILED_INSTANCE_FAILED:
      return {
        ...state,
        instanceDetails: {},
      };
    case CLEAR_INSTANCE_DATA_SAVE:
      return {
        ...state,
        instanceSaveDetails: [],
      };
    case LIST_SAVED_DETAILED_INSTANCE_SUCCESS:
      return {
        ...state,
        instanceSaveDetails: payload.data,
      };
    case LIST_SAVED_DETAILED_INSTANCE_FAILED:
      return {
        ...state,
        instanceSaveDetails: {},
      };
    case LIST_TIME_BASED_INSTANCE_SUCCESS:
    case LIST_EVENT_BASED_INSTANCE_SUCCESS:
      return {
        ...state,
        instanceData: payload.data,
        instaceType: payload.type,
      };
    case LIST_TIME_BASED_INSTANCE_FAILED:
    case LIST_EVENT_BASED_INSTANCE_FAILED:
      return {
        ...state,
        instanceData: [],
        instaceType: null,
      };
    case LIST_MY_COMPLIANCE_2_DATA:
      return {
        ...state,
        instanceDataLoaoding: payload,
      };
    case LIST_MY_COMPLIANCE_1_DATA:
      return {
        ...state,
        myComplianceOneDataLoading: payload,
      };
    case LIST_MY_COMPLIANCE_DETAIL_SUCCESS:
      return {
        ...state,
        myComplianceOneData: payload,
        loading: false,
      };
    case LIST_MY_COMPLIANCE_DETAIL_FAILED:
      return {
        ...state,
        myComplianceOneData: [],
        loading: false,
      };
    case UPLOADING_FILES_MY_COMPLIANCE:
      return {
        ...state,
        isFileUploading: payload.isUploading,
        isFileUploadingMessage: payload.message,
      };
    case RESET_ALERT_FOR_MY_COMPLIANCE:
    case SET_ALERT_FOR_MY_COMPLIANCE:
      return {
        ...state,
        isAlert: payload.isAlert,
        isAlertType: payload.alertType,
        alertMessage: payload.alertMessage,
      };
    default:
      return state;
  }
}

export default UserManagementReducer;
