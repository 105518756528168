import {
  SettingOutlined,
  UserAddOutlined,
  UserSwitchOutlined,
  QuestionCircleOutlined,
  FolderViewOutlined,
  ScheduleOutlined,
  FileSyncOutlined,
  FileMarkdownOutlined,
  ApartmentOutlined,
  BankOutlined,
  SlidersOutlined,
  CloudServerOutlined,
  SnippetsOutlined,
  IdcardOutlined,
  SafetyCertificateOutlined,
  UsergroupAddOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from '../configs/AppConfig';

const userNavTree = [
  {
    key: 'general-settings',
    path: `${APP_PREFIX_PATH}/`,
    title: 'sidenav.general-settings',
    icon: SettingOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: 'compliance-master',
        path: `${APP_PREFIX_PATH}/compliance-master`,
        title: 'sidenav.compliance-master',
        icon: FileMarkdownOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: 'matrics',
        path: `${APP_PREFIX_PATH}/matrics`,
        title: 'sidenav.matrics',
        icon: SlidersOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: 'Dashboards',
            path: `${APP_PREFIX_PATH}/matrices/dashboard`,
            title: 'sidenav.matrics-dashboard',
            icon: EyeOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: 'Reports',
            path: `${APP_PREFIX_PATH}/matrices/reports`,
            title: 'sidenav.matrics-reports',
            icon: SnippetsOutlined,
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
      {
        key: 'my-compliance-view',
        path: `${APP_PREFIX_PATH}/my-compliance-view`,
        title: 'sidenav.my-compliance-view',
        icon: FolderViewOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: 'compliance-view',
        path: `${APP_PREFIX_PATH}/compliance-calender-view`,
        title: 'sidenav.compliance-view-task',
        icon: IdcardOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: 'compliance-approval',
        path: `${APP_PREFIX_PATH}/compliance-approval`,
        title: 'sidenav.compliance-approval',
        icon: IdcardOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: 'events',
        path: `${APP_PREFIX_PATH}/events`,
        title: 'sidenav.events',
        icon: QuestionCircleOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: 'Certificates',
        path: `${APP_PREFIX_PATH}/matrices/certificate`,
        title: 'sidenav.matrics-certificates',
        icon: SafetyCertificateOutlined,
        breadcrumb: true,
        submenu: [],
      },
    ],
  },
  {
    key: 'application-settings',
    path: `${APP_PREFIX_PATH}/`,
    title: 'sidenav.applicaiton-settings',
    icon: SettingOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: 'settings',
        path: `${APP_PREFIX_PATH}/`,
        title: 'sidenav.settings',
        icon: SettingOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: 'compliance-admin',
            path: `${APP_PREFIX_PATH}/compliance-admin`,
            title: 'sidenav.compliance-admin',
            icon: UserSwitchOutlined,
            breadcrumb: true,
            submenu: [],
          },

          {
            key: 'company-management',
            path: `${APP_PREFIX_PATH}/company-management`,
            title: 'sidenav.company-management',
            icon: BankOutlined,
            breadcrumb: true,
            submenu: [],
          },

          {
            key: 'user-management',
            path: `${APP_PREFIX_PATH}/user-management`,
            title: 'sidenav.user-management',
            icon: UserAddOutlined,
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
    ],
  },
  {
    key: 'client-settings',
    path: `${APP_PREFIX_PATH}/`,
    title: 'sidenav.client-settings',
    icon: ScheduleOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: 'company-settings',
        path: `${APP_PREFIX_PATH}/`,
        title: 'sidenav.company-settings',
        icon: CloudServerOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: 'project-management',
            path: `${APP_PREFIX_PATH}/division-management`,
            title: 'sidenav.project-management',
            icon: ApartmentOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: 'company-compliance-mapping',
            path: `${APP_PREFIX_PATH}/company-compliance`,
            title: 'sidenav.company-compliance-mapping',
            icon: FileSyncOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: 'division-compliance',
            path: `${APP_PREFIX_PATH}/division-compliance`,
            title: 'sidenav.division-compliance',
            icon: UsergroupAddOutlined,
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
    ],
  },
];
const componentsNavTree = [
  {
    key: 'general-settings',
    path: `${APP_PREFIX_PATH}/`,
    title: 'sidenav.general-settings',
    icon: SettingOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: 'compliance-master',
        path: `${APP_PREFIX_PATH}/compliance-master`,
        title: 'sidenav.compliance-master',
        icon: FileMarkdownOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: 'matrics',
        path: `${APP_PREFIX_PATH}/matrics`,
        title: 'sidenav.matrics',
        icon: SlidersOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: 'Dashboards',
            path: `${APP_PREFIX_PATH}/matrices/dashboard`,
            title: 'sidenav.matrics-dashboard',
            icon: EyeOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: 'Reports',
            path: `${APP_PREFIX_PATH}/matrices/reports`,
            title: 'sidenav.matrics-reports',
            icon: SnippetsOutlined,
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
      {
        key: 'my-compliance-view',
        path: `${APP_PREFIX_PATH}/my-compliance-view`,
        title: 'sidenav.my-compliance-view',
        icon: FolderViewOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: 'compliance-view',
        path: `${APP_PREFIX_PATH}/compliance-calender-view`,
        title: 'sidenav.compliance-view-task',
        icon: IdcardOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: 'compliance-approval',
        path: `${APP_PREFIX_PATH}/compliance-approval`,
        title: 'sidenav.compliance-approval',
        icon: IdcardOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: 'events',
        path: `${APP_PREFIX_PATH}/events`,
        title: 'sidenav.events',
        icon: QuestionCircleOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: 'Certificates',
        path: `${APP_PREFIX_PATH}/matrices/certificate`,
        title: 'sidenav.matrics-certificates',
        icon: SafetyCertificateOutlined,
        breadcrumb: true,
        submenu: [],
      },
    ],
  },
  {
    key: 'application-settings',
    path: `${APP_PREFIX_PATH}/`,
    title: 'sidenav.applicaiton-settings',
    icon: SettingOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: 'settings',
        path: `${APP_PREFIX_PATH}/`,
        title: 'sidenav.settings',
        icon: SettingOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: 'compliance-admin',
            path: `${APP_PREFIX_PATH}/compliance-admin`,
            title: 'sidenav.compliance-admin',
            icon: UserSwitchOutlined,
            breadcrumb: true,
            submenu: [],
          },

          {
            key: 'company-management',
            path: `${APP_PREFIX_PATH}/company-management`,
            title: 'sidenav.company-management',
            icon: BankOutlined,
            breadcrumb: true,
            submenu: [],
          },

          {
            key: 'user-management',
            path: `${APP_PREFIX_PATH}/user-management`,
            title: 'sidenav.user-management',
            icon: UserAddOutlined,
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
    ],
  },
  {
    key: 'client-settings',
    path: `${APP_PREFIX_PATH}/`,
    title: 'sidenav.client-settings',
    icon: ScheduleOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: 'company-settings',
        path: `${APP_PREFIX_PATH}/`,
        title: 'sidenav.company-settings',
        icon: CloudServerOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: 'project-management',
            path: `${APP_PREFIX_PATH}/division-management`,
            title: 'sidenav.project-management',
            icon: ApartmentOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: 'company-compliance-mapping',
            path: `${APP_PREFIX_PATH}/company-compliance`,
            title: 'sidenav.company-compliance-mapping',
            icon: FileSyncOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: 'division-compliance',
            path: `${APP_PREFIX_PATH}/division-compliance`,
            title: 'sidenav.division-compliance',
            icon: UsergroupAddOutlined,
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
    ],
  },
];
var nav = [...componentsNavTree];

if (sessionStorage.getItem('role') === 'USER') {
  nav = userNavTree;
}

const navigationConfig = [...nav];

export default navigationConfig;
