import {
  ADD_PROJECT_FAILED,
  ADD_PROJECT_SUCCESS,
  EDIT_PROJECT_DATA,
  EDIT_PROJECT_FAILED,
  EDIT_PROJECT_SUCCESS,
  LIST_PROJECT_FAILED,
  LIST_PROJECT_SUCCESS,
  LOADING_TRUE_PROJECT,
  REMOVE_ISOPEN_PROJECT,
  REMOVE_PROJECT_ALERT,
  SET_ISOPEN_PROJECT,
  UNDER_PROCESSING_PROJECT,
  LOADING_FALSE_PROJECT,
  LIST_PROJECT_SUCCESS_SCP,
  RESET_DIVISION_DATA,
  LIST_PROJECT_FAILED_TOP,
  LIST_PROJECT_SUCCESS_TOP,
  LOGOUT,
  DEACTIVATE_PROJECT_FAILED,
} from '../../actions/types';
const initialState = {
  loading: true,
  isError: false,
  isErrorType: null,
  errMessage: null,
  companyId: null,
  selectedId: null,
  editProject: {},
  data: [],
  isOpen: false,
  isProcessing: false,
  SCP: [],
  scpLoading: true,
  disError: false,
  disErrorType: '',
  derrMessage: '',
  topProjectList: [],
};

function projectReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case DEACTIVATE_PROJECT_FAILED:
      return {
        ...state,
        disError: payload.error,
        disErrorType: payload.type,
        derrMessage: payload.message,
      };
    case LOGOUT:
      return {
        ...state,
        loading: true,
        isError: false,
        isErrorType: null,
        errMessage: null,
        companyId: null,
        selectedId: null,
        editProject: {},
        data: [],
        isOpen: false,
        isProcessing: false,
        SCP: [],
        scpLoading: true,
        topProjectList: [],
      };
    case LOADING_TRUE_PROJECT:
      return {
        ...state,
        loading: true,
      };
    case LOADING_FALSE_PROJECT:
      return {
        ...state,
        loading: false,
      };
    case LIST_PROJECT_SUCCESS_TOP:
      return {
        ...state,
        loading: false,
        topProjectList: payload.data,
      };
    case LIST_PROJECT_FAILED_TOP:
      return {
        ...state,
        loading: false,
        topProjectList: [],
      };
    case LIST_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: payload.data,
      };
    case LIST_PROJECT_SUCCESS_SCP:
      return {
        ...state,
        SCP: payload.data,
        scpLoading: false,
      };
    case LIST_PROJECT_FAILED:
      return {
        ...state,
        loading: false,
        isError: true,
        errMessage: payload.message,
        isErrorType: 'COMANY_LIST_ERROR',
        data: [],
      };
    case RESET_DIVISION_DATA:
      return {
        ...state,
        data: [],
      };
    case REMOVE_PROJECT_ALERT:
      return {
        ...state,
        loading: false,
        isError: false,
        isErrorType: null,
        errMessage: null,
        companyId: null,
        selectedId: null,
        isOpen: false,
      };
    case EDIT_PROJECT_SUCCESS:
    case ADD_PROJECT_SUCCESS:
      return {
        ...state,
        isError: false,
        isErrorType: payload.type,
        errMessage: payload.message,
        isProcessing: false,
      };
    case UNDER_PROCESSING_PROJECT:
      return {
        ...state,
        isProcessing: true,
      };
    case EDIT_PROJECT_FAILED:
    case ADD_PROJECT_FAILED:
      return {
        ...state,
        isError: true,
        isErrorType: payload.type,
        errMessage: payload.message,
        isProcessing: false,
      };
    case SET_ISOPEN_PROJECT:
      return {
        ...state,
        isOpen: true,
      };
    case REMOVE_ISOPEN_PROJECT:
      return {
        ...state,
        isOpen: false,
        isError: false,
        errMessage: null,
        isErrorType: null,
      };
    case EDIT_PROJECT_DATA:
      return {
        ...state,
        editProject: payload,
        isError: false,
        isErrorType: null,
        errMessage: null,
      };
    default:
      return state;
  }
}

export default projectReducer;
