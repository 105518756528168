import {
  ADD_OLD_USER_ID,
  CHECK_USER_EMAIL_INVALID,
  CHECK_USER_EMAIL_VALID,
  LOGOUT,
  REGISTER_USER_FAILED,
  RESET_ERROR_USER,
} from '../../actions/types';
const initialState = {
  loading: false,
  isError: false,
  isErrorType: null,
  errMessage: null,
  isUser: null,
  emailUserId: null,
  oldEmailUserId: null,
  data: null,
};

function userReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LOGOUT:
      return {
        loading: false,
        isError: false,
        isErrorType: null,
        errMessage: null,
        isUser: null,
        emailUserId: null,
        oldEmailUserId: null,
        data: null,
      };
    case REGISTER_USER_FAILED:
    case CHECK_USER_EMAIL_INVALID:
      return {
        ...state,
        loading: false,
        isError: true,
        isErrorType: payload.type,
        errMessage: payload.message,
        isUser: false,
        emailUserId: null,
      };
    case CHECK_USER_EMAIL_VALID:
      return {
        ...state,
        loading: false,
        isError: false,
        isErrorType: payload.type,
        errMessage: payload.message,
        isUser: true,
        emailUserId: payload.data.data.id,
        data: payload.data.data,
      };
    case ADD_OLD_USER_ID:
      return {
        ...state,
        oldEmailUserId: payload,
      };
    case RESET_ERROR_USER:
      return {
        ...state,
        isUser: false,
        isError: false,
        isErrorType: null,
        errMessage: null,
      };
    default:
      return state;
  }
}

export default userReducer;
