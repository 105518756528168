import axios from 'axios';
import {
  ADD_PROJECT_FAILED,
  ADD_PROJECT_SUCCESS,
  BACKEND_URL,
  DEACTIVATE_PROJECT_FAILED,
  EDIT_PROJECT_FAILED,
  EDIT_PROJECT_SUCCESS,
  LIST_PROJECT_FAILED,
  LIST_PROJECT_SUCCESS,
  SET_LOGOUT_PROVISION,
  REMOVE_PROJECT_ALERT,
  SET_ISOPEN_PROJECT,
  BACKEND_URL_2,
  LIST_PROJECT_SUCCESS_TOP,
  LIST_PROJECT_FAILED_TOP,
} from '../../actions/types';
import store from '../../redux/store';

let listProjectsAbort = null;
export const listProjects = () => async (dispatch) => {
  if (listProjectsAbort !== null) {
    listProjectsAbort.cancel('New Call made');
    listProjectsAbort = null;
  }
  listProjectsAbort = axios.CancelToken.source();
  const config = {
    'Content-Type': 'application/json',
  };
  const company_id = store.getState().auth.data.company_id;
  const company = sessionStorage.getItem('company_selected');
  await axios({
    method: 'POST',
    url: BACKEND_URL_2 + 'project-management/list-division',
    data: { company_id, company },
    headers: config,
    cancelToken: listProjectsAbort.token,
  })
    .then(async (response) => {
      if (response.data.statuscode === 200) {
        await dispatch({
          type: LIST_PROJECT_SUCCESS,
          payload: {
            message: response.data.message,
            type: 'PROJECT_MANAGEMENT',
            data: response.data.data,
          },
        });
      } else if (response.data.statuscode === 403) {
        await dispatch({ type: SET_LOGOUT_PROVISION });
      } else if (response.data.statuscode === 500) {
        dispatch({
          type: LIST_PROJECT_FAILED,
          payload: {
            message: 'Server Erorr',
            type: 'PROJECT_MANAGEMENT',
            data: null,
          },
        });
      } else {
        dispatch({
          type: LIST_PROJECT_FAILED,
          payload: {
            message: response.data.message,
            type: 'PROJECT_MANAGEMENT',
            data: null,
          },
        });
      }
    })
    .catch((error) => {
      setTimeout(() => {
        dispatch({ type: REMOVE_PROJECT_ALERT });
      }, 12000);
    });
};

let listProjectsTopAbort = null;
export const listProjectsTop = (company) => async (dispatch) => {
  if (listProjectsTopAbort !== null) {
    listProjectsTopAbort.cancel('New Call made');
    listProjectsTopAbort = null;
  }
  listProjectsTopAbort = axios.CancelToken.source();
  const config = {
    'Content-Type': 'application/json',
  };
  const company_id = store.getState().auth.data.company_id;
  await axios({
    method: 'POST',
    url: BACKEND_URL_2 + 'project-management/list-division',
    data: { company_id, company },
    headers: config,
    cancelToken: listProjectsTopAbort.token,
  })
    .then(async (response) => {
      if (response.data.statuscode === 200) {
        await dispatch({
          type: LIST_PROJECT_SUCCESS_TOP,
          payload: {
            message: response.data.message,
            type: 'PROJECT_MANAGEMENT',
            data: response.data.data,
          },
        });
      } else if (response.data.statuscode === 403) {
        await dispatch({ type: SET_LOGOUT_PROVISION });
      } else if (response.data.statuscode === 500) {
        dispatch({
          type: LIST_PROJECT_FAILED_TOP,
          payload: {
            message: 'Server Erorr',
            type: 'PROJECT_MANAGEMENT',
            data: null,
          },
        });
      } else {
        dispatch({
          type: LIST_PROJECT_FAILED_TOP,
          payload: {
            message: response.data.message,
            type: 'PROJECT_MANAGEMENT',
            data: null,
          },
        });
      }
    })
    .catch((error) => {
      setTimeout(() => {
        dispatch({ type: REMOVE_PROJECT_ALERT });
      }, 12000);
    });
};

export const getRole =
  (name, functionz, location, subsidiary, plant_store, tag) =>
  async (dispatch) => {
    const config = {
      'Content-Type': 'application/json',
    };
    const body = { role_name: 'Project-Admin' };
    await axios({
      method: 'POST',
      url: BACKEND_URL + 'access/get-role',
      data: body,
      headers: config,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          dispatch(
            createProject(
              name,
              functionz,
              location,
              subsidiary,
              plant_store,
              tag,
              response.data.data.id
            )
          );
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
        } else if (response.data.statuscode === 500) {
          dispatch({
            type: ADD_PROJECT_FAILED,
            payload: {
              type: 'PROJECT_CREATE',
              message: 'Server Error',
            },
          });
          setTimeout(() => {
            dispatch({
              type: REMOVE_PROJECT_ALERT,
            });
          }, 12000);
        } else {
          dispatch({
            type: ADD_PROJECT_FAILED,
            payload: {
              type: 'PROJECT_CREATE',
              message: response.data.message,
            },
          });
          setTimeout(() => {
            dispatch({
              type: REMOVE_PROJECT_ALERT,
            });
          }, 12000);
        }
      })
      .catch((error) => {
        setTimeout(() => {
          dispatch({
            type: REMOVE_PROJECT_ALERT,
          });
        }, 12000);
      });
  };

export const createUserProjectRole =
  (name, role_id, functionz, location, subsidiary, plant_store, tag, project) =>
  async (dispatch) => {
    const config = {
      'Content-Type': 'application/json',
    };
    const body = {
      user: store.getState().user.emailUserId,
      company_id: parseInt(store.getState().auth.data.company_id),
      company: sessionStorage.getItem('company_selected'),
      role: role_id,
      project: project,
      flag: 'PROJECT-MANAGEMENT',
    };
    await axios({
      method: 'POST',
      url: BACKEND_URL + 'org/create-usercompanyrole',
      data: body,
      headers: config,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          await dispatch(
            assignProjectUser(
              response.data.data,
              project,
              functionz,
              location,
              subsidiary,
              plant_store,
              tag,
              name
            )
          );
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
        } else if (response.data.statuscode === 500) {
          dispatch({
            type: ADD_PROJECT_FAILED,
            payload: {
              type: 'PROJECT_CREATE',
              message: 'Server Error',
            },
          });
          setTimeout(() => {
            dispatch({
              type: REMOVE_PROJECT_ALERT,
            });
          }, 12000);
        } else {
          dispatch({
            type: ADD_PROJECT_FAILED,
            payload: {
              type: 'PROJECT_CREATE',
              message: response.data.message,
            },
          });
          setTimeout(() => {
            dispatch({
              type: REMOVE_PROJECT_ALERT,
            });
          }, 12000);
        }
      })
      .catch((error) => {
        setTimeout(() => {
          dispatch({
            type: REMOVE_PROJECT_ALERT,
          });
        }, 12000);
      });
  };

export const createRoleForUser =
  (role_name, rank, company, project) => async (dispatch) => {
    const config = {
      'Content-Type': 'application/json',
    };
    const body = {
      company,
      project,
      role_name,
      rank,
    };
    await axios({
      method: 'POST',
      url: BACKEND_URL + 'project-management/create-project-roles',
      data: body,
      headers: config,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
        } else if (response.data.statuscode === 500) {
        } else {
        }
      })
      .catch((error) => {});
  };

export const createProject =
  (name, functionz, location, subsidiary, plant_store, tag, role_id) =>
  async (dispatch) => {
    const config = {
      'Content-Type': 'application/json',
    };
    const body = {
      company_id: store.getState().auth.data.company_id,
      client_id: sessionStorage.getItem('company_selected'),
      name,
    };
    await axios({
      method: 'POST',
      url: BACKEND_URL + 'project/create-project',
      data: body,
      headers: config,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          dispatch(
            createUserProjectRole(
              name,
              role_id,
              functionz,
              location,
              subsidiary,
              plant_store,
              tag,
              response.data.data
            )
          );
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
        } else if (response.data.statuscode === 500) {
          dispatch({
            type: ADD_PROJECT_FAILED,
            payload: {
              type: 'PROJECT_CREATE',
              message: 'Server Error',
            },
          });
          setTimeout(() => {
            dispatch({
              type: REMOVE_PROJECT_ALERT,
            });
          }, 12000);
        } else {
          dispatch({
            type: ADD_PROJECT_FAILED,
            payload: {
              type: 'PROJECT_CREATE',
              message: response.data.message,
            },
          });
          setTimeout(() => {
            dispatch({
              type: REMOVE_PROJECT_ALERT,
            });
          }, 12000);
        }
      })
      .catch((error) => {
        setTimeout(() => {
          dispatch({
            type: REMOVE_PROJECT_ALERT,
          });
        }, 12000);
      });
  };

export const createDivision =
  (project, functionz, location, subsidiary, plant_store, tag, name) =>
  async (dispatch) => {
    const config = {
      'Content-Type': 'application/json',
    };
    const body = {
      // company_id: store.getState().auth.data.company_id,
      company: sessionStorage.getItem('company_selected'),
      division: project,
      function: functionz,
      location,
      subsidiary,
      plant_store,
      tag,
      name,
    };
    await axios({
      method: 'POST',
      url: BACKEND_URL_2 + 'project-management/create-division ',
      data: body,
      headers: config,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          dispatch({
            type: ADD_PROJECT_SUCCESS,
            payload: {
              type: 'PROJECT_CREATE',
              message: response.data.message,
            },
          });
          dispatch(listProjects());
          setTimeout(() => {
            dispatch({
              type: REMOVE_PROJECT_ALERT,
            });
            dispatch({
              type: SET_ISOPEN_PROJECT,
            });
            dispatch({
              type: REMOVE_PROJECT_ALERT,
            });
          }, 4000);
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
        } else if (response.data.statuscode === 500) {
          dispatch({
            type: ADD_PROJECT_FAILED,
            payload: {
              type: 'PROJECT_CREATE',
              message: 'Server Error',
            },
          });
          setTimeout(() => {
            dispatch({
              type: REMOVE_PROJECT_ALERT,
            });
          }, 12000);
        } else {
          dispatch({
            type: ADD_PROJECT_FAILED,
            payload: {
              type: 'PROJECT_CREATE',
              message: response.data.message,
            },
          });
          setTimeout(() => {
            dispatch({
              type: REMOVE_PROJECT_ALERT,
            });
          }, 12000);
        }
      })
      .catch((error) => {
        setTimeout(() => {
          dispatch({
            type: REMOVE_PROJECT_ALERT,
          });
        }, 12000);
      });
  };

export const assignProjectUser =
  (user, project, functionz, location, subsidiary, plant_store, tag, name) =>
  async (dispatch) => {
    const config = {
      'Content-Type': 'application/json',
    };
    const body = {
      company_id: store.getState().auth.data.company_id,
      client_id: sessionStorage.getItem('company_selected'),
      user,
      project,
    };
    await axios({
      method: 'POST',
      url: BACKEND_URL + 'project/assign-project',
      data: body,
      headers: config,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          dispatch(
            createDivision(
              project,
              functionz,
              location,
              subsidiary,
              plant_store,
              tag,
              name
            )
          );
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
        } else if (response.data.statuscode === 500) {
          dispatch({
            type: ADD_PROJECT_FAILED,
            payload: {
              type: 'PROJECT_CREATE',
              message: 'Server Error',
            },
          });
          setTimeout(() => {
            dispatch({
              type: REMOVE_PROJECT_ALERT,
            });
          }, 12000);
        } else {
          dispatch({
            type: ADD_PROJECT_FAILED,
            payload: {
              type: 'PROJECT_CREATE',
              message: response.data.message,
            },
          });
          setTimeout(() => {
            dispatch({
              type: REMOVE_PROJECT_ALERT,
            });
          }, 12000);
        }
      })
      .catch((error) => {
        setTimeout(() => {
          dispatch({
            type: REMOVE_PROJECT_ALERT,
          });
        }, 12000);
      });
  };

export const editProjectFunc =
  (name, functionz, location, subsidiary, plant_store, tag, flag) =>
  async (dispatch) => {
    const config = {
      'Content-Type': 'application/json',
    };
    const body = {
      name,
      project: store.getState().project.editProject.div_data.id,
      company_id: store.getState().auth.data.company_id,
      client_id: sessionStorage.getItem('company_selected'),
    };
    await axios({
      method: 'POST',
      url: BACKEND_URL + 'project/edit-project',
      data: body,
      headers: config,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          await dispatch(
            editDivisionFunc(
              functionz,
              location,
              subsidiary,
              plant_store,
              tag,
              name,
              flag
            )
          );
          await dispatch(listProjects());
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
        } else if (response.data.statuscode === 500) {
          dispatch({
            type: EDIT_PROJECT_FAILED,
            payload: {
              type: 'PROJECT_CREATE',
              message: 'Server Error',
            },
          });
          setTimeout(() => {
            dispatch({
              type: REMOVE_PROJECT_ALERT,
            });
          }, 12000);
        } else {
          dispatch({
            type: ADD_PROJECT_FAILED,
            payload: {
              type: 'PROJECT_CREATE',
              message: response.data.message,
            },
          });
          setTimeout(() => {
            dispatch({
              type: REMOVE_PROJECT_ALERT,
            });
          }, 12000);
        }
      })
      .catch((error) => {
        setTimeout(() => {
          dispatch({
            type: REMOVE_PROJECT_ALERT,
          });
        }, 12000);
      });
  };
export const editDivisionFunc =
  (functionz, location, subsidiary, plant_store, tag, name, flag) =>
  async (dispatch) => {
    const config = {
      'Content-Type': 'application/json',
    };
    const body = {
      function: functionz,
      location,
      subsidiary,
      plant_store,
      tag,
      division: store.getState().project.editProject.division,
      company: sessionStorage.getItem('company_selected'),
      name,
    };
    await axios({
      method: 'POST',
      url: BACKEND_URL_2 + 'project-management/edit-division',
      data: body,
      headers: config,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          if (flag) {
            dispatch({
              type: EDIT_PROJECT_SUCCESS,
              payload: {
                type: 'PROJECT_EDIT',
                message: response.data.message,
              },
            });
            dispatch(listProjects());
            setTimeout(() => {
              dispatch({
                type: REMOVE_PROJECT_ALERT,
              });
              dispatch({
                type: SET_ISOPEN_PROJECT,
              });
              dispatch({
                type: REMOVE_PROJECT_ALERT,
              });
            }, 4000);
          }
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
        } else if (response.data.statuscode === 500) {
          dispatch({
            type: EDIT_PROJECT_FAILED,
            payload: {
              type: 'PROJECT_CREATE',
              message: 'Server Error',
            },
          });
          setTimeout(() => {
            dispatch({
              type: REMOVE_PROJECT_ALERT,
            });
          }, 12000);
        } else {
          dispatch({
            type: ADD_PROJECT_FAILED,
            payload: {
              type: 'PROJECT_CREATE',
              message: response.data.message,
            },
          });
          setTimeout(() => {
            dispatch({
              type: REMOVE_PROJECT_ALERT,
            });
          }, 12000);
        }
      })
      .catch((error) => {
        setTimeout(() => {
          dispatch({
            type: REMOVE_PROJECT_ALERT,
          });
        }, 12000);
      });
  };

export const deactivateProject = (project_id, status) => async (dispatch) => {
  const config = {
    'Content-Type': 'application/json',
  };
  const company_id = store.getState().auth.data.company_id;
  const body = {
    company_id,
    company: sessionStorage.getItem('company_selected'),
    division: project_id,
    status,
  };
  await axios({
    method: 'POST',
    url: BACKEND_URL_2 + 'project-management/delete-division',
    data: body,
    headers: config,
  })
    .then(async (response) => {
      if (response.data.statuscode === 200) {
        dispatch(listProjects());
      } else if (response.data.statuscode === 403) {
        await dispatch({ type: SET_LOGOUT_PROVISION });
      } else {
        dispatch({
          type: DEACTIVATE_PROJECT_FAILED,
          payload: {
            message: response.data.hasOwnProperty('message')
              ? response.data.message
              : 'Server Error',
            type: 'PROJECT_MANAGEMENT',
            error: true,
          },
        });
        setTimeout(() => {
          dispatch({
            type: DEACTIVATE_PROJECT_FAILED,
            payload: {
              message: '',
              type: '',
              error: false,
            },
          });
        }, 1000);
      }
    })
    .catch((error) => {
      setTimeout(() => {
        dispatch({
          type: DEACTIVATE_PROJECT_FAILED,
          payload: {
            message: '',
            type: '',
            error: false,
          },
        });
      }, 1000);
    });
};

export const getRoleEdit = (project, project_user_id) => async (dispatch) => {
  const config = {
    'Content-Type': 'application/json',
  };
  const body = { role_name: 'Project-Admin' };
  await axios({
    method: 'POST',
    url: BACKEND_URL + 'access/get-role',
    data: body,
    headers: config,
  })
    .then(async (response) => {
      if (response.data.statuscode === 200) {
        dispatch(
          createUserProjectRoleEdit(
            project,
            response.data.data.id,
            project_user_id
          )
        );
      } else if (response.data.statuscode === 403) {
        await dispatch({ type: SET_LOGOUT_PROVISION });
      } else if (response.data.statuscode === 500) {
        dispatch({
          type: ADD_PROJECT_FAILED,
          payload: {
            type: 'PROJECT_CREATE',
            message: 'Server Error',
          },
        });
        setTimeout(() => {
          dispatch({
            type: REMOVE_PROJECT_ALERT,
          });
        }, 12000);
      } else {
        dispatch({
          type: ADD_PROJECT_FAILED,
          payload: {
            type: 'PROJECT_CREATE',
            message: response.data.message,
          },
        });
        setTimeout(() => {
          dispatch({
            type: REMOVE_PROJECT_ALERT,
          });
        }, 12000);
      }
    })
    .catch((error) => {
      setTimeout(() => {
        dispatch({
          type: REMOVE_PROJECT_ALERT,
        });
      }, 12000);
    });
};

export const createUserProjectRoleEdit =
  (project, role_id, project_user_id) => async (dispatch) => {
    const config = {
      'Content-Type': 'application/json',
    };
    const body = {
      user: store.getState().user.emailUserId,
      company_id: parseInt(store.getState().auth.data.company_id),
      company: sessionStorage.getItem('company_selected'),
      role: role_id,
      project: project,
      flag: 'PROJECT-MANAGEMENT',
    };
    await axios({
      method: 'POST',
      url: BACKEND_URL + 'org/create-usercompanyrole',
      data: body,
      headers: config,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          dispatch(
            assignProjectUserEdit(response.data.data, project, project_user_id)
          );
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
        } else if (response.data.statuscode === 500) {
          dispatch({
            type: ADD_PROJECT_FAILED,
            payload: {
              type: 'PROJECT_CREATE',
              message: 'Server Error',
            },
          });
          setTimeout(() => {
            dispatch({
              type: REMOVE_PROJECT_ALERT,
            });
          }, 12000);
        } else {
          dispatch({
            type: ADD_PROJECT_FAILED,
            payload: {
              type: 'PROJECT_CREATE',
              message: response.data.message,
            },
          });
          setTimeout(() => {
            dispatch({
              type: REMOVE_PROJECT_ALERT,
            });
          }, 12000);
        }
      })
      .catch((error) => {
        setTimeout(() => {
          dispatch({
            type: REMOVE_PROJECT_ALERT,
          });
        }, 12000);
      });
  };

export const assignProjectUserEdit =
  (user, project, project_user_id) => async (dispatch) => {
    const config = {
      'Content-Type': 'application/json',
    };
    const body = {
      company_id: store.getState().auth.data.company_id,
      client_id: sessionStorage.getItem('company_selected'),
      user,
      project,
    };
    await axios({
      method: 'POST',
      url: BACKEND_URL + 'project/assign-project',
      data: body,
      headers: config,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          dispatch(statusProjectUser(project_user_id));
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
        } else if (response.data.statuscode === 500) {
          dispatch({
            type: ADD_PROJECT_FAILED,
            payload: {
              type: 'PROJECT_CREATE',
              message: 'Server Error',
            },
          });
          setTimeout(() => {
            dispatch({
              type: REMOVE_PROJECT_ALERT,
            });
          }, 12000);
        } else {
          dispatch({
            type: ADD_PROJECT_FAILED,
            payload: {
              type: 'PROJECT_CREATE',
              message: response.data.message,
            },
          });
          setTimeout(() => {
            dispatch({
              type: REMOVE_PROJECT_ALERT,
            });
          }, 12000);
        }
      })
      .catch((error) => {
        setTimeout(() => {
          dispatch({
            type: REMOVE_PROJECT_ALERT,
          });
        }, 12000);
      });
  };

export const statusProjectUser = (project_user_id) => async (dispatch) => {
  const config = {
    'Content-Type': 'application/json',
  };
  const body = {
    project_user_id,
    user_status: false,
    company_id: sessionStorage.getItem('company_selected'),
  };
  await axios({
    method: 'POST',
    url: BACKEND_URL + 'project/edit-project-user-id',
    data: body,
    headers: config,
  })
    .then(async (response) => {
      if (response.data.statuscode === 200) {
        dispatch({
          type: EDIT_PROJECT_SUCCESS,
          payload: {
            type: 'PROJECT_EDIT',
            message: response.data.message,
          },
        });
        dispatch(listProjects());
        setTimeout(() => {
          dispatch({
            type: REMOVE_PROJECT_ALERT,
          });
          dispatch({
            type: SET_ISOPEN_PROJECT,
          });
          dispatch({
            type: REMOVE_PROJECT_ALERT,
          });
        }, 4000);
      } else if (response.data.statuscode === 403) {
        await dispatch({ type: SET_LOGOUT_PROVISION });
      } else {
        dispatch({
          type: EDIT_PROJECT_FAILED,
          payload: {
            type: 'PROJECT_EDIT',
            message: response.data.message,
          },
        });
        setTimeout(() => {
          dispatch({
            type: REMOVE_PROJECT_ALERT,
          });
          dispatch({
            type: SET_ISOPEN_PROJECT,
          });
          dispatch({
            type: REMOVE_PROJECT_ALERT,
          });
        }, 4000);
      }
    })
    .catch((error) => {});
};
