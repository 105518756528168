import axios from 'axios';
import {
  ADD_USER_LIST_DATA_FAILED,
  ADD_USER_LIST_DATA_SUCCESS,
  BACKEND_URL,
  EDIT_USER_LIST_DATA_FAILED,
  EDIT_USER_LIST_DATA_SUCCESS,
  LIST_USER_BY_EMAIL_DATA_SUCESS,
  LIST_USER_COMPANY_LIST_SUCCESS,
  LIST_USER_LIST_DATA_FAILED,
  LIST_USER_LIST_DATA_SUCCESS,
  LIST_USER_PROJECT_LIST_SUCCESS,
  SET_LOGOUT_PROVISION,
  REMOVE_ISOPEN_USER_LIST_DATA,
  REMOVE_USER_LIST_DATA_ALERT,
  SET_ISOPEN_USER_LIST_DATA,
} from '../../actions/types';
import store from '../../redux/store';

let listUserAbort = null;
export const listUsersData = () => async (dispatch) => {
  if (listUserAbort !== null) {
    listUserAbort.cancel('New Call made');
    listUserAbort = null;
  }
  listUserAbort = axios.CancelToken.source();
  const config = {
    'Content-Type': 'application/json',
  };
  const company_id = store.getState().auth.data.company_id;
  await axios({
    method: 'POST',
    url: BACKEND_URL + 'org/list-users',
    data: { company_id },
    headers: config,
    cancelToken: listUserAbort.token,
  })
    .then(async (response) => {
      if (response.data.statuscode === 200) {
        dispatch({
          type: LIST_USER_LIST_DATA_SUCCESS,
          payload: {
            message: response.data.message,
            data: response.data.data,
            type: 'LIST_USER_MANAGEMENT',
          },
        });
      } else if (response.data.statuscode === 403) {
        await dispatch({ type: SET_LOGOUT_PROVISION });
      } else {
        dispatch({
          type: LIST_USER_LIST_DATA_FAILED,
          payload: {
            message: response.data.message,
            type: 'LIST_USER_MANAGEMENT',
          },
        });
      }
    })
    .catch((error) => {
    });
};

export const statusUserUpdate = (user_id, status) => async (dispatch) => {
  const config = {
    'Content-Type': 'application/json',
  };
  const body = { user_id, status };
  await axios({
    method: 'POST',
    url: BACKEND_URL + 'access/deactivate-user',
    data: body,
    headers: config,
  })
    .then(async (response) => {
      if (response.data.statuscode === 200) {
        dispatch(listUsersData());
      } else if (response.data.statuscode === 403) {
        await dispatch({ type: SET_LOGOUT_PROVISION });
      } else {
        dispatch({
          type: ADD_USER_LIST_DATA_FAILED,
          payload: {
            message: response.data.hasOwnProperty('message')
              ? response.data.message
              : 'Server Error',
            type: 'STATUS_USER_DATA',
          },
        });
        setTimeout(() => {
          dispatch({
            type: REMOVE_USER_LIST_DATA_ALERT,
          });
        }, 1000);
      }
    })
    .catch((error) => {});
};

export const createUserApiCall =
  (
    first_name,
    last_name,
    email,
    designation,
    reporting_manager_id,
    reporting_manager_email
  ) =>
  async (dispatch) => {
    const config = {
      'Content-Type': 'application/json',
    };
    const body = {
      first_name,
      last_name,
      email,
      name: first_name + ' ' + last_name,
    };
    if (designation !== '') {
      body.designation = designation;
    }
    if (reporting_manager_id !== '') {
      body.reporting_manager_id = reporting_manager_id;
    }
    if (reporting_manager_email !== '') {
      body.reporting_manager_email = reporting_manager_email;
    }
    await axios({
      method: 'POST',
      url: BACKEND_URL + 'access/register-user',
      data: body,
      headers: config,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          dispatch({
            type: ADD_USER_LIST_DATA_SUCCESS,
            payload: { message: response.data.message, type: 'ADD_USER_DATA' },
          });
          dispatch(listUsersData());
          setTimeout(() => {
            dispatch({
              type: REMOVE_USER_LIST_DATA_ALERT,
            });
            dispatch({
              type: SET_ISOPEN_USER_LIST_DATA,
            });
            dispatch({
              type: REMOVE_ISOPEN_USER_LIST_DATA,
            });
          }, 4000);
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
        } else if (response.data.statuscode === 500) {
          dispatch({
            type: ADD_USER_LIST_DATA_FAILED,
            payload: { message: 'Server Error', type: 'ADD_USER_DATA' },
          });
          setTimeout(() => {
            dispatch({
              type: REMOVE_USER_LIST_DATA_ALERT,
            });
          }, 12000);
        } else {
          dispatch({
            type: ADD_USER_LIST_DATA_FAILED,
            payload: { message: response.data.message, type: 'ADD_USER_DATA' },
          });
          setTimeout(() => {
            dispatch({
              type: REMOVE_USER_LIST_DATA_ALERT,
            });
          }, 12000);
        }
      })
      .catch((error) => {
        setTimeout(() => {
          dispatch({
            type: REMOVE_USER_LIST_DATA_ALERT,
          });
        }, 4000);
      });
  };

export const getUserByMail = (email) => async (dispatch) => {
  const config = {
    'Content-Type': 'application/json',
  };
  const body = { email };
  await axios({
    method: 'POST',
    url: BACKEND_URL + 'access/list-user-byemail',
    data: body,
    headers: config,
  })
    .then(async (response) => {
      if (response.data.statuscode === 200) {
        dispatch({
          type: LIST_USER_BY_EMAIL_DATA_SUCESS,
          payload: {
            data: response.data.data,
          },
        });
      } else if (response.data.statuscode === 403) {
        await dispatch({ type: SET_LOGOUT_PROVISION });
      } else {
      }
    })
    .catch((error) => {});
};

export const editUserApiCall =
  (
    first_name,
    last_name,
    email,
    designation,
    reporting_manager_id,
    reporting_manager_email
  ) =>
  async (dispatch) => {
    const config = {
      'Content-Type': 'application/json',
    };
    const body = {
      user_id: store.getState().userManagement.userByEmail.id,
      first_name,
      last_name,
      email,
      name: first_name + ' ' + last_name,
    };
    if (designation !== '') {
      body.designation = designation;
    }
    if (reporting_manager_id !== '') {
      body.reporting_manager_id = reporting_manager_id;
    }
    if (reporting_manager_email !== '') {
      body.reporting_manager_email = reporting_manager_email;
    }
    await axios({
      method: 'POST',
      url: BACKEND_URL + 'access/edit-user-byid',
      data: body,
      headers: config,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          dispatch({
            type: EDIT_USER_LIST_DATA_SUCCESS,
            payload: { message: response.data.message, type: 'ADD_USER_DATA' },
          });
          dispatch(listUsersData());
          setTimeout(() => {
            dispatch({
              type: REMOVE_USER_LIST_DATA_ALERT,
            });
            dispatch({
              type: SET_ISOPEN_USER_LIST_DATA,
            });
            dispatch({
              type: REMOVE_ISOPEN_USER_LIST_DATA,
            });
          }, 4000);
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
        } else if (response.data.statuscode === 500) {
          dispatch({
            type: EDIT_USER_LIST_DATA_FAILED,
            payload: { message: 'Server Error', type: 'ADD_USER_DATA' },
          });
          setTimeout(() => {
            dispatch({
              type: REMOVE_USER_LIST_DATA_ALERT,
            });
          }, 12000);
        } else {
          dispatch({
            type: EDIT_USER_LIST_DATA_FAILED,
            payload: { message: response.data.message, type: 'ADD_USER_DATA' },
          });
          setTimeout(() => {
            dispatch({
              type: REMOVE_USER_LIST_DATA_ALERT,
            });
          }, 12000);
        }
      })
      .catch((error) => {
        setTimeout(() => {
          dispatch({
            type: REMOVE_USER_LIST_DATA_ALERT,
          });
        }, 4000);
      });
  };

export const listUserCompanyData = (email) => async (dispatch) => {
  const config = {
    'Content-Type': 'application/json',
  };
  const body = { email };
  await axios({
    method: 'POST',
    url: BACKEND_URL + 'org/list-company-byemail',
    data: body,
    headers: config,
  })
    .then(async (response) => {
      if (response.data.statuscode === 200) {
        dispatch({
          type: LIST_USER_COMPANY_LIST_SUCCESS,
          payload: response.data.data,
        });
      } else if (response.data.statuscode === 403) {
        await dispatch({ type: SET_LOGOUT_PROVISION });
      } else {
      }
    })
    .catch((error) => {});
};

export const listUserProjectData = (email) => async (dispatch) => {
  const config = {
    'Content-Type': 'application/json',
  };
  const body = { email };
  await axios({
    method: 'POST',
    url: BACKEND_URL + 'project/list-project-byemail',
    data: body,
    headers: config,
  })
    .then(async (response) => {
      if (response.data.statuscode === 200) {
        dispatch({
          type: LIST_USER_PROJECT_LIST_SUCCESS,
          payload: response.data.data,
        });
      } else if (response.data.statuscode === 403) {
        await dispatch({ type: SET_LOGOUT_PROVISION });
      } else {
      }
    })
    .catch((error) => {});
};
