import React from "react";
import { connect } from "react-redux";
import SideNav from "../../components/layout-components/SideNav";
import TopNav from "../../components/layout-components/TopNav";
import Loading from "../../components/shared-components/Loading";
import MobileNav from "../../components/layout-components/MobileNav";
import HeaderNav from "../../components/layout-components/HeaderNav";
import PageHeader from "../../components/layout-components/PageHeader";
import AppViews from "../../views/app-views";
import { Layout, Grid } from "antd";

import navigationConfig from "../../configs/NavigationConfig";
import {
  SIDE_NAV_WIDTH,
  SIDE_NAV_COLLAPSED_WIDTH,
  NAV_TYPE_SIDE,
  NAV_TYPE_TOP,
  DIR_RTL,
  DIR_LTR,
} from "../../constants/ThemeConstant";
import utils from "../../utils";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { Redirect } from "react-router-dom";
import { APP_PREFIX_PATH } from "../../configs/AppConfig";
// import { logout } from "../../apis/login/auth";
// import store from "../../redux/store";

const { Content } = Layout;
const { useBreakpoint } = Grid;
// var idleTimeVal = 15;
// var idleTime = 0;

// var clearIntervalz = null;

export const AppLayout = ({
  navCollapsed,
  navType,
  location,
  direction,
  idleTimeOut,
  resetTIme,
}) => {
  // useEffect(() => {
  //   clearInterval(clearIntervalz);
  //   clearIntervalz = setInterval(() => {
  //     idleTime += 1;
  //     if (idleTime > idleTimeVal) {
  //       store.dispatch(logout());
  //     }
  //   }, 60000);
  // }, []);

  // useEffect(() => {
  //   if (resetTIme) {
  //     idleTime = 0;
  //   }
  // }, [resetTIme]);

  const currentRouteInfo = utils.getRouteInfo(
    navigationConfig,
    location.pathname
  );

  // useEffect(() => {
  //   if (idleTimeOut !== null) {
  //     idleTimeVal = idleTimeOut;
  //   }
  // }, [idleTimeOut]);

  const screens = utils.getBreakPoint(useBreakpoint());
  const isMobile = !screens.includes("lg");
  const isNavSide = navType === NAV_TYPE_SIDE;
  const isNavTop = navType === NAV_TYPE_TOP;
  const getLayoutGutter = () => {
    if (isNavTop || isMobile) {
      return 0;
    }
    return navCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH;
  };

  const { status } = useThemeSwitcher();

  const triggerMoveMent = () => {
    // idleTime = 0;
  };

  if (status === "loading") {
    return <Loading cover="page" />;
  }

  const getLayoutDirectionGutter = () => {
    if (direction === DIR_LTR) {
      return { paddingLeft: getLayoutGutter() };
    }
    if (direction === DIR_RTL) {
      return { paddingRight: getLayoutGutter() };
    }
    return { paddingLeft: getLayoutGutter() };
  };
  if (location.pathname === "/app/" || location.pathname === "/app") {
    return <Redirect to={`${APP_PREFIX_PATH}/matrices/dashboard`} />;
  }
  return (
    <Layout onMouseMove={triggerMoveMent} onKeyPress={triggerMoveMent}>
      <HeaderNav isMobile={isMobile} />
      {isNavTop && !isMobile ? <TopNav routeInfo={currentRouteInfo} /> : null}
      <Layout className="app-container">
        {isNavSide && !isMobile ? (
          <SideNav routeInfo={currentRouteInfo} />
        ) : null}
        <Layout className="app-layout" style={getLayoutDirectionGutter()}>
          <div className={`app-content ${isNavTop ? "layout-top-nav" : ""}`}>
            {currentRouteInfo?.title === "sidenav.my-compliance-view" ||
            currentRouteInfo?.title === "sidenav.events" ||
            currentRouteInfo?.title === "sidenav.compliance-view-task" ||
            currentRouteInfo?.title === "sidenav.compliance-approval" ? (
              ""
            ) : (
              <PageHeader
                display={currentRouteInfo?.breadcrumb}
                title={currentRouteInfo?.title}
              />
            )}

            <Content>
              <AppViews />
            </Content>
          </div>
        </Layout>
      </Layout>
      {isMobile && <MobileNav />}
    </Layout>
  );
};

const mapStateToProps = ({ theme, auth }) => {
  const { navCollapsed, navType, locale } = theme;
  const { idleTimeOut, resetTIme } = auth;
  return { navCollapsed, navType, locale, idleTimeOut, resetTIme };
};

export default connect(mapStateToProps)(React.memo(AppLayout));
