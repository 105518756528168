import axios from "axios";
import {
  LOGIN_FAILED,
  LOGIN_SUCCESS,
  TOKEN_CHECK,
  TOKEN_CHECK_FAIL,
  LOGOUT,
  BACKEND_URL,
  RESET_SUCCESS,
  RESET_FAILED,
  GET_USER_DATA_BY_EMAIL,
  SET_LOADING_FALSE,
  SET_LOGOUT_PROVISION,
  RESET_LOGIN_FAILED,
  OTP_SETTING,
  CUSTOM_USER,
  BACKEND_URL_2,
  IDLE_TIMEOUT,
  SET_OTP_TIMEOUT,
  OTP_FREEZE,
  SET_CHECK_TOKEN_BOOL,
} from "../../actions/types";
import setAuthToken from "../../utils/setAuthToken";
import store from "../../redux/store";
import { listCompany } from "../dashboard/company";
import { listUsersData } from "../dashboard/userManagement";
import { listComplianceAdmin } from "../dashboard/complianceAdminCrud";
// import { listCompliances } from "../dashboard/complianceMaster";
import { Crypt } from "hybrid-crypto-js";
import { checkProgressDetails } from "./user";
const publicKey = `-----BEGIN PUBLIC KEY-----
MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC13UDmL+Acqc2LJGyrBGJTnb2o
9KvnrFftGSQPvE1tDoqV6Ms2602sQVL/TX4inZuDr5axIBNIdWsuU6xe0eX3TMw5
2WOnTcNtLFDiCB1IaWGgOHWQ1kaL5coz4v+PR4ymPfBLZSqS+c0BzM6RWyXnsFv8
Br7O1xXKjDpKozcQCQIDAQAB
-----END PUBLIC KEY-----`;

//check token
export const loadUser = () => async (dispatch) => {
  if (sessionStorage.token) {
    setAuthToken(sessionStorage.token);
    await axios({
      method: "POST",
      url: BACKEND_URL + "access/check-token",
      data: {},
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          await dispatch({
            type: IDLE_TIMEOUT,
            payload: response.data.idle_time,
          });
          await setAuthToken(false);
          await setAuthToken(response.data.new_token);
          sessionStorage.setItem("current_user_id", response.data.user_id);
          sessionStorage.setItem("role", response.data.role[0]);
          await dispatch({
            type: TOKEN_CHECK,
            payload: {
              token: response.data.new_token,
              data: response.data.role,
              company_id: response.data.company_id,
            },
          });
          sessionStorage.setItem("token", response.data.new_token);
          if (response.data.role[0] === "CUSTOM-USER") {
            // console.log(response.data.role)
            await dispatch(customUser());
          }
          await dispatch(getUserData(store.getState().auth.data.email));
          await dispatch(listCompany());
          dispatch(listUsersData());
          dispatch(listComplianceAdmin());
          dispatch({ type: SET_CHECK_TOKEN_BOOL, payload: true });
          // dispatch(listCompliances());
          dispatch(checkProgressDetails());
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
        } else {
          dispatch({
            type: TOKEN_CHECK_FAIL,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: TOKEN_CHECK_FAIL,
        });
      });
  } else {
    dispatch({ type: SET_LOADING_FALSE });
    await dispatch({ type: LOGOUT });
  }
};

//CUSTOMUSER ROLE
export const customUser = () => async (dispatch) => {
  const config = {
    "Content-Type": "application/json",
  };
  const body = {};
  await axios({
    method: "POST",
    url: BACKEND_URL_2 + "project-management/get-user-role-by-token",
    data: body,
    headers: config,
  })
    .then((response) => {
      if (response.data.statuscode === 200) {
        dispatch({
          type: CUSTOM_USER,
          payload: response.data.role_name,
        });
      } else {
        dispatch({
          type: CUSTOM_USER,
          payload: null,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: LOGIN_FAILED,
        payload: { message: "server error" },
      });
    });
};
//Login user
export const login = (email, password) => async (dispatch) => {
  const config = {
    "Content-Type": "application/json",
  };
  var crypt = new Crypt();
  var encrypted = crypt.encrypt(publicKey, password);
  var data = JSON.parse(encrypted);
  var encData =
    data.iv + "$|" + data.cipher + "$|" + data.keys[Object.keys(data.keys)[0]];

  const body = { email, password: encData };
  await axios({
    method: "POST",
    url: BACKEND_URL + "access/loginapi",
    data: body,
    headers: config,
  })
    .then((response) => {
      if (response.data.statuscode === 200) {
        dispatch({
          type: OTP_SETTING,
          payload: response.data,
        });
        // dispatch(loadUser());
      } else {
        dispatch({
          type: LOGIN_FAILED,
          payload: { message: response.data.message, type: "LOGIN" },
        });
        setTimeout((e) => {
          dispatch({ type: RESET_LOGIN_FAILED });
        }, 6000);
      }
    })
    .catch((error) => {
      dispatch({
        type: LOGIN_FAILED,
        payload: {
          message: error.response.data.hasOwnProperty("message")
            ? error.response.data.message
            : "server error",
          type: "LOGIN",
        },
      });
      setTimeout((e) => {
        dispatch({ type: RESET_LOGIN_FAILED });
      }, 6000);
    });
};

//otp
//Login user
export const OTPloginAPi = (otp) => async (dispatch) => {
  dispatch({ type: OTP_FREEZE, payload: true });
  const config = {
    "Content-Type": "application/json",
  };
  const body = { email: sessionStorage.getItem("email"), otp };
  await axios({
    method: "POST",
    url: BACKEND_URL + "access/validate-otp",
    data: body,
    headers: config,
  })
    .then(async (response) => {
      if (response.data.statuscode === 200) {
        await dispatch({
          type: LOGIN_SUCCESS,
          payload: response.data,
        });
        await dispatch(loadUser());
      } else {
        dispatch({
          type: LOGIN_FAILED,
          payload: response.data,
        });
        setTimeout((e) => {
          dispatch({ type: RESET_LOGIN_FAILED });
        }, 6000);
      }
      await dispatch({ type: OTP_FREEZE, payload: false });
    })
    .catch((error) => {
      dispatch({ type: OTP_FREEZE, payload: false });
      dispatch({
        type: LOGIN_FAILED,
        payload: {
          message: error.response.data.hasOwnProperty("message")
            ? error.response.data.message
            : "server error",
        },
      });
      setTimeout((e) => {
        dispatch({ type: RESET_LOGIN_FAILED });
      }, 6000);
    });
};

export const SssoLoginValidate = (meta_data) => async (dispatch) => {
  const config = {
    "Content-Type": "application/json",
  };
  const body = { meta_data };
  await axios({
    method: "POST",
    url: BACKEND_URL + "access/validate-adfs-token",
    data: body,
    headers: config,
  })
    .then(async (response) => {
      if (response.data.statuscode === 200) {
        sessionStorage.setItem("company_id", response.data.company_id);
        sessionStorage.setItem("email", response.data.email);
        sessionStorage.setItem("name", response.data.name);
        sessionStorage.setItem("role", response.data.role);
        sessionStorage.setItem("token", response.data.token);
        window.location.href = "/";
        // await dispatch(loadUser());
      } else {
        dispatch({
          type: LOGIN_FAILED,
          payload: { message: response.data.message, type: "LOGIN" },
        });
        setTimeout((e) => {
          dispatch({ type: RESET_LOGIN_FAILED });
        }, 6000);
      }
    })
    .catch((error) => {
      dispatch({
        type: LOGIN_FAILED,
        payload: {
          message: error.response.data.hasOwnProperty("message")
            ? error.response.data.message
            : "server error",
          type: "LOGIN",
        },
      });
      setTimeout((e) => {
        dispatch({ type: RESET_LOGIN_FAILED });
      }, 6000);
    });
};

//LOGOUT
export const logout = () => async (dispatch) => {
  if (sessionStorage.getItem("token") === null) {
    return;
  }
  const body = { email: store.getState().auth.data.email };
  const config = {
    "Content-Type": "application/json",
  };
  await axios({
    method: "POST",
    url: BACKEND_URL + "access/logout",
    data: body,
    headers: config,
  })
    .then(async (response) => {
      if (response.data.statuscode === 200) {
        await dispatch({ type: LOGOUT });
        sessionStorage.clear();
      } else if (response.data.statuscode === 403) {
        await dispatch({ type: SET_LOGOUT_PROVISION });
      } else {
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

//forgot password
export const forgotPassword = (email) => async (dispatch) => {
  const config = {
    "Content-Type": "application/json",
  };
  const body = { email };
  await axios({
    method: "POST",
    url: BACKEND_URL + "access/reset-password",
    data: body,
    headers: config,
  })
    .then((response) => {
      if (response.data.statuscode === 200) {
        dispatch({
          type: RESET_SUCCESS,
          payload: response.data,
        });
        setTimeout((e) => {
          dispatch({ type: RESET_LOGIN_FAILED });
        }, 6000);
      } else {
        dispatch({
          type: RESET_FAILED,
          payload: response.data,
        });
        setTimeout((e) => {
          dispatch({ type: RESET_LOGIN_FAILED });
        }, 6000);
      }
    })
    .catch((error) => {
      dispatch({
        type: RESET_FAILED,
        payload: { message: "server error" },
      });
      setTimeout((e) => {
        dispatch({ type: RESET_LOGIN_FAILED });
      }, 6000);
    });
};

//OTP Timer for reset password
export const getOtpTime = (email) => async (dispatch) => {
  const config = {
    "Content-Type": "application/json",
  };
  const body = { email };
  await axios({
    method: "POST",
    url: BACKEND_URL + "access/get-otp-expiry-duration",
    data: body,
    headers: config,
  })
    .then((response) => {
      if (response.data.statuscode === 200) {
        dispatch({
          type: SET_OTP_TIMEOUT,
          payload: response.data.duration,
        });
      } else {
        dispatch({
          type: SET_OTP_TIMEOUT,
          payload: 0,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: SET_OTP_TIMEOUT,
        payload: 0,
      });
    });
};

//OTP Timer for reset password
export const resendOtp = (email) => async (dispatch) => {
  const config = {
    "Content-Type": "application/json",
  };
  const body = { email };
  await axios({
    method: "POST",
    url: BACKEND_URL + "access/resend-otp",
    data: body,
    headers: config,
  })
    .then((response) => {
      if (response.data.statuscode === 200) {
        dispatch({
          type: SET_OTP_TIMEOUT,
          payload: response.data.duration,
        });
      } else {
        dispatch({
          type: SET_OTP_TIMEOUT,
          payload: 0,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: SET_OTP_TIMEOUT,
        payload: 0,
      });
    });
};

//reset password
export const resetPassword = (email, otp, new_password) => async (dispatch) => {
  const config = {
    "Content-Type": "application/json",
  };
  var crypt = new Crypt();
  var encrypted = crypt.encrypt(publicKey, new_password);
  var data = JSON.parse(encrypted);
  var encData =
    data.iv + "$|" + data.cipher + "$|" + data.keys[Object.keys(data.keys)[0]];
  const body = { email, otp, new_password: encData };
  await axios({
    method: "POST",
    url: BACKEND_URL + "access/update-password",
    data: body,
    headers: config,
  })
    .then((response) => {
      if (response.data.statuscode === 200) {
        dispatch({
          type: RESET_SUCCESS,
          payload: response.data,
        });
        setTimeout((e) => {
          dispatch({ type: RESET_LOGIN_FAILED });
        }, 6000);
      } else {
        dispatch({
          type: RESET_FAILED,
          payload: response.data,
        });
        setTimeout((e) => {
          dispatch({ type: RESET_LOGIN_FAILED });
        }, 6000);
      }
    })
    .catch((error) => {
      dispatch({
        type: RESET_FAILED,
        payload: { message: "server error" },
      });
      setTimeout((e) => {
        dispatch({ type: RESET_LOGIN_FAILED });
      }, 6000);
    });
};

//get user data
export const getUserData = (email) => async (dispatch) => {
  const config = {
    "Content-Type": "application/json",
  };
  const body = { email };
  await axios({
    method: "POST",
    url: BACKEND_URL + "access/list-user-byemail",
    data: body,
    headers: config,
  })
    .then(async (response) => {
      if (response.data.statuscode === 200) {
        dispatch({
          type: GET_USER_DATA_BY_EMAIL,
          payload: response.data,
        });
      } else if (response.data.statuscode === 403) {
        await dispatch({ type: SET_LOGOUT_PROVISION });
      } else {
      }
    })
    .catch((error) => {});
};
