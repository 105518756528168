import {
  CREATE_CXO_ADMIN_FAILED,
  CREATE_CXO_ADMIN_SUCCESS,
  LIST_CXO_ADMIN_FAILED,
  LIST_CXO_ADMIN_SUCCESS,
  LOGOUT,
  REMOVE_CXO_ADMIN_ALERT,
  SET_ISOPEN_CXO,
} from '../../actions/types';
const initialState = {
  cxoAdmin: [],
  isError: false,
  adminloading: true,
  isErrorType: null,
  errMessage: null,
  isOpen: false,
  isAlert: false,
  isAlertError: false,
  alertMessage: null,
};

function complianceReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LOGOUT:
      return {
        cxoAdmin: [],
        isError: false,
        adminloading: true,
        isErrorType: null,
        errMessage: null,
        isOpen: false,
        isAlert: false,
        isAlertError: false,
        alertMessage: null,
      };
    case LIST_CXO_ADMIN_FAILED:
      return {
        ...state,
        cxoAdmin: [],
        isError: true,
        errMessage: payload.message,
        isErrorType: 'COMANY_LIST_ERROR',
        adminloading: false,
      };
    case LIST_CXO_ADMIN_SUCCESS:
      return {
        ...state,
        isError: false,
        adminloading: false,
        cxoAdmin: payload.data,
      };
    case REMOVE_CXO_ADMIN_ALERT:
      return {
        ...state,
        isError: false,
        isErrorType: null,
        errMessage: null,
        isOpen: false,
      };
    case CREATE_CXO_ADMIN_FAILED:
      return {
        ...state,
        isError: true,
        isErrorType: payload.type,
        errMessage: payload.message,
      };
    case SET_ISOPEN_CXO:
      return {
        ...state,
        isOpen: true,
      };
    case CREATE_CXO_ADMIN_SUCCESS:
      return {
        ...state,
        isError: false,
        isErrorType: payload.type,
        errMessage: payload.message,
      };
    default:
      return state;
  }
}

export default complianceReducer;
