export const ComplianceMasterColumns = [
  { key: "category", value: "Category" },
  { key: "act", value: "Act" },
];

export const MyComplianceColumns = [
  { key: "category", value: "Category" },
  { key: "act", value: "Act" },
  { key: "priority", value: "Priority" },
  { key: "requisite", value: "Requisite" },
  { key: "plant_store", value: "Plant/Store" },
  { key: "owner", value: "Owner" },
  { key: "escalator1", value: "Escalator 1" },
  { key: "escalator2", value: "Escalator 2" },
  { key: "escalator3", value: "Escalator 3" },
  { key: "function", value: "Function" },
  { key: "requirements", value: "Description" },
  { key: "location", value: "Location" },
];

export const ApprovalColumns = [
  { key: "priority", value: "Priority" },
  { key: "requisite", value: "Requisite" },
  { key: "plant_store", value: "Plant/Store" },
  { key: "owner", value: "Owner" },
  { key: "escalator1", value: "Escalator 1" },
  { key: "function", value: "Function" },
  { key: "location", value: "Location" },
];

export const DashboardColumns = [
  { key: "category", value: "Category" },
  { key: "act", value: "Act" },
  { key: "requirements", value: "Description" },
  { key: "function", value: "Function" },
  { key: "location", value: "Location" },
];

export const EventColumns = [{ key: "requirements", value: "Description" }];

export const tableRange = 20;
