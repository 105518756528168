import axios from 'axios';
import {
  BACKEND_URL,
  CHECK_USER_EMAIL_VALID,
  CHECK_USER_EMAIL_INVALID,
  REGISTER_USER_FAILED,
  CREATE_COMPANY_FAILED,
  CREATE_COMPANY_SUCCESS,
  REMOVE_COMPANY_ALERT,
  CLOSE_ADD_MODAL,
  DEACTIVE_USER_ROLE_FAILED,
  CLOSE_ADD_MODAL_RESET,
  RESET_ERROR_USER,
  SET_LOGOUT_PROVISION,
  BACKEND_URL_2,
  LIST_OF_ACTIVE_PROCESS,
} from '../../actions/types';
import store from '../../redux/store';
import { listCompany } from '../dashboard/company';

export const checkUserByEmail = (email) => async (dispatch) => {
  const config = {
    'Content-Type': 'application/json',
  };
  const body = { email };
  await axios({
    method: 'POST',
    url: BACKEND_URL + 'access/list-user-byemail',
    data: body,
    headers: config,
  })
    .then(async (response) => {
      if (response.data.statuscode === 200) {
        dispatch({
          type: CHECK_USER_EMAIL_VALID,
          payload: {
            message: response.data.message,
            type: 'COMPANY_MANAGEMENT',
            data: response.data,
          },
        });
      } else if (response.data.statuscode === 403) {
        await dispatch({ type: SET_LOGOUT_PROVISION });
      } else {
        dispatch({
          type: CHECK_USER_EMAIL_INVALID,
          payload: {
            message: response.data.message,
            type: 'COMPANY_MANAGEMENT',
          },
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: CHECK_USER_EMAIL_INVALID,
        payload: { message: 'server error' },
      });
    });
};

let checkProgressDetailsAbort = null;
export const checkProgressDetails = () => async (dispatch) => {
  if (checkProgressDetailsAbort !== null) {
    checkProgressDetailsAbort.cancel('New Call made');
    checkProgressDetailsAbort = null;
  }
  checkProgressDetailsAbort = axios.CancelToken.source();
  const config = {
    'Content-Type': 'application/json',
  };
  const body = {};
  await axios({
    method: 'POST',
    url: BACKEND_URL_2 + '/project-management/list-active-process',
    data: body,
    headers: config,
    cancelToken: checkProgressDetailsAbort.token,
  })
    .then(async (response) => {
      checkProgressDetailsAbort = null;
      if (response.data.statuscode === 200) {
        await dispatch({
          type: LIST_OF_ACTIVE_PROCESS,
          payload: response.data.data,
        });
      } else if (response.data.statuscode === 403) {
        await dispatch({ type: SET_LOGOUT_PROVISION });
      } else {
        await dispatch({
          type: LIST_OF_ACTIVE_PROCESS,
          payload: [],
        });
      }
    })
    .catch((error) => {
      checkProgressDetailsAbort = null;
      dispatch({
        type: LIST_OF_ACTIVE_PROCESS,
        payload: [],
      });
    });
};

export const createNewUser =
  (first_name, last_name, email) => async (dispatch) => {
    const config = {
      'Content-Type': 'application/json',
    };
    const name = first_name + ' ' + last_name;
    const body = { first_name, last_name, name, email };
    await axios({
      method: 'POST',
      url: BACKEND_URL + 'access/register-user',
      data: body,
      headers: config,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          dispatch(checkUserByEmail(email));
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
        } else {
          dispatch({
            type: REGISTER_USER_FAILED,
            payload: {
              message: response.data.message,
              type: 'COMPANY_MANAGEMENT',
            },
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: REGISTER_USER_FAILED,
          payload: { message: 'server error' },
        });
      });
  };

export const getRole =
  (role_name, companyId, flag, target, dataz) => async (dispatch) => {
    const config = {
      'Content-Type': 'application/json',
    };
    const body = { role_name };
    await axios({
      method: 'POST',
      url: BACKEND_URL + 'access/get-role',
      data: body,
      headers: config,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          if (target === 'EDIT') {
            dispatch(
              deactivateUserCompanyRole(
                response.data.data.id,
                companyId,
                dataz,
                flag,
                target
              )
            );
          } else {
            dispatch(
              createUserCompanyRole(
                response.data.data.id,
                companyId,
                flag,
                target,
                dataz
              )
            );
          }
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
        } else {
          dispatch({
            type: CREATE_COMPANY_FAILED,
            payload: {
              message: response.data.message,
              type: 'COMPANY_MANAGEMENT',
            },
          });
          setTimeout(() => {
            dispatch({ type: REMOVE_COMPANY_ALERT });
          }, 12000);
        }
      })
      .catch((error) => {
        dispatch({
          type: CREATE_COMPANY_FAILED,
          payload: {
            message: 'Server Error',
            type: 'COMPANY_MANAGEMENT',
          },
        });
        setTimeout(() => {
          dispatch({ type: REMOVE_COMPANY_ALERT });
        }, 12000);
      });
  };

export const createUserCompanyRole =
  (role_id, companyId, flag, target, dataz) => async (dispatch) => {
    const config = {
      'Content-Type': 'application/json',
    };
    const body = {
      user: store.getState().user.emailUserId,
      // company_id: store.getState().auth.data.company_id,
      company: companyId,
      role: role_id,
      flag: flag,
    };
    await axios({
      method: 'POST',
      url: BACKEND_URL + 'org/create-usercompanyrole',
      data: body,
      headers: config,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          dispatch({
            type: CREATE_COMPANY_SUCCESS,
            payload: {
              message: dataz.message,
              type: 'COMPANY_CREATED',
              data: dataz,
            },
          });
          setTimeout(() => {
            dispatch({ type: REMOVE_COMPANY_ALERT });
            dispatch({ type: CLOSE_ADD_MODAL });
            dispatch({ type: CLOSE_ADD_MODAL_RESET });
            dispatch({ type: RESET_ERROR_USER });
          }, 4000);
          dispatch(listCompany());
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
        } else {
          dispatch({
            type: CREATE_COMPANY_FAILED,
            payload: {
              message: response.data.message,
              type: 'COMPANY_MANAGEMENT',
            },
          });
          setTimeout(() => {
            dispatch({ type: REMOVE_COMPANY_ALERT });
          }, 12000);
        }
      })
      .catch((error) => {
        dispatch({
          type: CREATE_COMPANY_FAILED,
          payload: {
            message: 'Server Error',
            type: 'COMPANY_MANAGEMENT',
          },
        });
        setTimeout(() => {
          dispatch({ type: REMOVE_COMPANY_ALERT });
        }, 12000);
      });
  };

export const deactivateUserCompanyRole =
  (role, company, dataz, flag, target) => async (dispatch) => {
    const config = {
      'Content-Type': 'application/json',
    };
    const company_id = store.getState().auth.data.company_id;
    const user = store.getState().user.oldEmailUserId;
    const body = { company_id, user, role, company };
    await axios({
      method: 'POST',
      url: BACKEND_URL + 'org/deactivate-usercompanyrole',
      data: body,
      headers: config,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          dispatch(createUserCompanyRole(role, company, flag, target, dataz));
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
        } else {
          dispatch({
            type: DEACTIVE_USER_ROLE_FAILED,
            payload: {
              message: response.data.message,
              type: 'COMPANY_MANAGEMENT',
            },
          });
          setTimeout(() => {
            dispatch({ type: REMOVE_COMPANY_ALERT });
          }, 12000);
        }
      })
      .catch((error) => {
        dispatch({
          type: DEACTIVE_USER_ROLE_FAILED,
          payload: {
            message: 'Server Error',
            type: 'COMPANY_MANAGEMENT',
          },
        });
        setTimeout(() => {
          dispatch({ type: REMOVE_COMPANY_ALERT });
        }, 12000);
      });
  };
