import {
  CREATE_COMPANY_FAILED,
  CREATE_COMPANY_SUCCESS,
  REMOVE_COMPANY_ALERT,
  CLOSE_ADD_MODAL,
  LIST_COMPANY_FAILED,
  LIST_COMPANY_SUCESS,
  EDIT_COMPANY_DATA,
  EDIT_COMPNAY_FAILED,
  EDIT_COMPNAY_SUCCESS,
  CLOSE_EDIT_MODAL,
  RESET_ERROR_USER,
  CLOSE_ADD_MODAL_RESET,
  CLOSE_EDIT_MODAL_RESET,
  DEACTIVE_USER_ROLE_FAILED,
  SETTINGS_DATA_SUCCESS,
  LOGOUT,
  TRIGGER_DASHBOARD_API,
} from '../../actions/types';
const initialState = {
  loading: true,
  isError: false,
  isErrorType: null,
  errMessage: null,
  companyId: null,
  selectedId: null,
  editCompany: {},
  data: [],
  companyDetails: {},
  isOpen: false,
  triggerDashboardApi: false,
};

function companyReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case TRIGGER_DASHBOARD_API:
      return {
        ...state,
        triggerDashboardApi: payload,
      };
    case LOGOUT:
      return {
        ...state,
        loading: true,
        isError: false,
        isErrorType: null,
        errMessage: null,
        companyId: null,
        selectedId: null,
        editCompany: {},
        data: [],
        companyDetails: {},
        isOpen: false,
      };
    case EDIT_COMPNAY_FAILED:
    case CREATE_COMPANY_FAILED:
    case DEACTIVE_USER_ROLE_FAILED:
      return {
        ...state,
        loading: false,
        isError: true,
        isErrorType: payload.type,
        errMessage: payload.message,
        companyId: null,
        selectedId: null,
      };
    case CREATE_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        isError: false,
        isErrorType: payload.type,
        errMessage: payload.message,
        companyId: payload.data.data,
        selectedId: null,
      };
    case EDIT_COMPNAY_SUCCESS:
      return {
        ...state,
        loading: false,
        isError: false,
        isErrorType: payload.type,
        errMessage: payload.message,
        companyId: payload.data,
        selectedId: null,
      };
    case REMOVE_COMPANY_ALERT:
      return {
        ...state,
        loading: false,
        isError: false,
        isErrorType: null,
        errMessage: null,
        companyId: null,
        selectedId: null,
        isOpen: false,
      };

    case CLOSE_EDIT_MODAL:
    case CLOSE_ADD_MODAL:
      return {
        ...state,
        isOpen: true,
      };
    case CLOSE_ADD_MODAL_RESET:
    case CLOSE_EDIT_MODAL_RESET:
      return {
        ...state,
        isOpen: false,
      };
    case LIST_COMPANY_SUCESS:
      return {
        ...state,
        loading: false,
        isError: false,
        data: payload.data,
      };
    case LIST_COMPANY_FAILED:
      return {
        ...state,
        loading: false,
        isError: true,
        errMessage: payload.message,
        isErrorType: 'COMANY_LIST_ERROR',
        data: [],
      };
    case EDIT_COMPANY_DATA:
      return {
        ...state,
        editCompany: payload,
      };
    case RESET_ERROR_USER:
      return {
        ...state,
        isOpen: false,
      };
    case SETTINGS_DATA_SUCCESS:
      return {
        ...state,
        companyDetails: payload,
      };
    default:
      return state;
  }
}

export default companyReducer;
